import React, { useState } from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { TextField } from '@mui/material';
import { height } from '@mui/system';


export default function EditBio(props) {
    const {open, editBio, close, isSuccess, bio} = props
    const [newBio, setBio] = useState(bio)

    const closePopup = () =>{
        setBio('')
        close()
    }
    
    return (
        <div>
            <Dialog open={open} onClose={close} fullWidth={true} maxWidth="lg">
                <DialogTitle>Edit Teachers Bio</DialogTitle>
                {isSuccess ? 
                <DialogContent>
                    <DialogContentText>
                    The profile has been updated.
                    </DialogContentText>
                </DialogContent>
                :
                <DialogContent>
                    <TextField id="bio" label="Bio" variant="standard" className='generate' onChange={(e)=>{setBio(e.target.value)}} value={newBio} style={{height:250, width:'100%'}} multiline={true}/>
                </DialogContent> }
                {isSuccess ?
                <DialogActions>
                    <button type="submit" onClick={closePopup} className="buttonMain">
                        <span>Close</span>
                    </button>
                </DialogActions> : 
                <DialogActions>
                    <button type="submit" onClick={()=>{editBio(newBio)}} className="buttonMain">
                        <span>Save</span>
                    </button>
                </DialogActions> }
            </Dialog>
        </div>
    )
}
