import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import Header from './common/Header'
import UserContext from '../helper/UserContext'
import AddAPhotoOutlinedIcon from '@mui/icons-material/AddAPhotoOutlined';
import axios from 'axios';
import LoadingInfo from './common/LoadingInfo';

class AddService extends Component {
    static contextType = UserContext
    constructor(props){
        super(props)
        this.state={
          selectedFile:'',
          service_name:'',
          isModalOpen:false,
          isImageUpdated:false,
          service_id:''
        }
    }
    async componentDidMount(){
      console.log(this.props)
        let service_id = this.props.match.params._id
        if(service_id != null ){
            this.getServiceDetails(service_id)
        }
    }
    getServiceDetails = async (service_id) =>{
        try{
            let url = `${process.env.REACT_APP_BASE_API_URL}/management/services/getService?service_id=${service_id}`
            let config = {
                headers:{
                    authorization:'Bearer ' + localStorage.getItem('token')
                }
            }
            const service = await axios.get(url,config)
            this.setState({
                service_name:service.data.service.service_name,
                fileURL:service.data.service.service_image_url,
                service_id:service_id,
                selectedFile:service.data.service_image_url
            })
        }catch(err){
            console.log(err)
        }
    }
    selectFile = (event) =>{
      this.fileInput.click()
    }
    handleFile = (e)=>{
      console.log(e.target.files[0])
      this.setState({
        selectedFile:e.target.files[0],
        fileURL:URL.createObjectURL(e.target.files[0]),
        isImageUpdated:true
      })
    }
    handleText = (e)=>{
      this.setState({
        [e.target.name]:e.target.value
      })
    }
    saveService = async () =>{
      this.setState({isModalOpen:true, message:'Please wait...'})
      try{
        let url = `${process.env.REACT_APP_BASE_API_URL}/management/services/createNew`
        let config = {
          headers:{
            authorization:'Bearer ' + localStorage.getItem('token')
          }
        }
        const formData = new FormData();
        formData.append("service_name", this.state.service_name);
        formData.append("service_image", this.state.selectedFile);
        const _saveService = await axios.post(url,formData,config)
        this.setState({message:'The skill has been added successfully. '})
      }catch(err){
        console.log(err)
      }
    }
    updateService = async () =>{
      this.setState({isModalOpen:true, message:'Please wait...'})
      try{
        let url = `${process.env.REACT_APP_BASE_API_URL}/management/services/updateService`
        let config = {
          headers:{
            authorization:'Bearer ' + localStorage.getItem('token')
          }
        }
        let params = {
          service_id:this.state.service_id,
          service_name:this.state.service_name
        }
        
        const _updateService = await axios.post(url,params,config)
        this.setState({message:'The skill has been updated successfully. '})
      }catch(err){
        console.log(err)
      }
    }
    closeModal = () =>{
      this.props.history.goBack()
    }
    removeService = async () =>{
      try{
        let url = `${process.env.REACT_APP_BASE_API_URL}/management/skills/removeSkill`
        let params = {
          skill_id:this.state.skill_id
        }
        let config = {
          headers:{
            authorization:'Bearer ' + localStorage.getItem('token')
          }
        }
        const removeSkill = await axios.post(url,params,config)
        if(removeSkill.status == 200){
          this.props.history.goBack()
        }
      }catch(err){
        console.log(err)
      }
    }
  render() {
    return (
        <div className='contentContainer'>
            <Header header={"Add New Service"} user={this.context.user} showBack={true} buttonTitle={"Add New Service"}/>
            <div className='clientDetailContainer'>
              <div className='infoContainer'>
                <span className='title'>Photo</span>
                <span className='imagePicker' onClick={()=>{this.selectFile()}}>
                  {this.state.selectedFile == '' ?
                    <AddAPhotoOutlinedIcon className='photoIcon'/>
                    :
                    <img src={this.state.fileURL} style={{width:'auto'}}/>
                  }
                </span>
                <input type='file' ref={(ref)=>this.fileInput = ref} style={{display:'none'}} onChange={(e)=>{this.handleFile(e)}}/>
              </div>
              <div className='infoContainer heightFix' style={{paddingBottom:15}}>
                <span className='title'>Information</span>
                <div className='newNanny' style={{flexDirection:'column', width:'90%'}}>
                  <input type="text" name="service_name" id='service_name' placeholder="Holiday" onChange={(e)=>{this.handleText(e)}} value={this.state.service_name}/>
                </div>
              </div>
                <button type="submit" name="login" id="login" className="saveButton" onClick={()=>{this.state.service_id != '' ? this.updateService() : this.saveService()}}>Save</button>
                {this.state.service_id != '' ? <button type="submit" name="login" id="login" className="saveButton cancel" onClick={()=>{this.removeService()}}>Delete</button> : null }
            </div>
            <LoadingInfo title={"Add new service"} message={this.state.message} open={this.state.isModalOpen} close={this.closeModal}/>
        </div>
    )
  }
}
export default withRouter(AddService)