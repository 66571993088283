import React, { Component } from "react";
import UserContext from "../../helper/UserContext";
import Header from "../common/Header";
import {
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  CircularProgress,
  Select,
  MenuItem,
  Autocomplete,
  Box,
  Input,
} from "@mui/material";
import axios from "axios";
import Message from "../common/Message";

export default class TestUsers extends Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      testUsers: [],
      user: null,
    };
  }

  componentDidMount() {
    this.getTestUsers();
    this.getAllUsers();
  }

  getTestUsers = async () => {
    try {
      let url = `${process.env.REACT_APP_BASE_API_URL}/management/settings/getTestUsers`;
      let config = {
        headers: {
          authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      let testers = await axios.get(url, config);
      console.log(testers);
      if (testers.status == 200) {
        this.setState({
          testUsers: testers.data.testers,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
  getAllUsers = async () => {
    try {
      let url = `${process.env.REACT_APP_BASE_API_URL}/management/notifications/getUsers`;
      let config = {
        headers: {
          authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      let users = await axios.get(url, config);
      if (users.status == 200) {
        this.setState({
          users: users.data.users,
        });
      }
      console.log(users);
    } catch (err) {
      console.log(err);
    }
  };
  render() {
    return (
      <div className="notificationInputBox">
        <Autocomplete
          id="user"
          fullWidth
          options={this.state.users}
          style={{ marginTop: 25, marginBottom: 25 }}
          value={this.state.user}
          autoHighlight
          getOptionLabel={(option) => option.email}
          onChange={(e, option) => {
            this.setState({ user: option });
          }}
          renderOption={(props, option) => (
            <Box
              component="li"
              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
              key={option.userID}
              {...props}
            >
              <img
                src={option.profile_image}
                style={{ width: 50, height: 50, borderRadius: 25 }}
              />
              {option.name}<br/>
              {option.email}
            </Box>
          )}
          renderInput={(params) => (
            <TextField {...params} label="Choose a user" />
          )}
        />
      </div>
    );
  }
}
