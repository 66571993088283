import { Button } from "@mui/material";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import Cancellation from "./common/Cancellation";

export default class JobDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      job: {},
      address: {},
      applicants: [],
      custom_days: [],
      selected_teacher: {},
      showCancellation: false,
    };
  }
  componentDidMount = () => {
    this.setState({
      job: this.props.job,
      address: this.props.job.address,
      applicants: this.props.job.applicants,
      custom_days: this.props.job.custom_days,
      selected_teacher: this.props.job.selected_teacher,
    });
  };
  getDuration = (_start, _end) => {
    let end = moment(_end);
    let start = moment(_start);
    let _diff = end.diff(start, "hours", true);
    let str = _diff.toString();
    if (str.length > 3) _diff = _diff.toFixed(1);
    return _diff;
  };

  closeCancellation = () => {
    this.setState({ showCancellation: false });
  };
  deleteJob = (reason) => {
    this.props.deleteJob(reason);
  };

  render() {
    return (
      <div className="jobDetail">
        <Button className="closeButton" onClick={this.props.close}>
          X
        </Button>
        <div style={{ width: "95%" }}>
          <Link
            to={{
              pathname: `/clientDetails/${this.state.job.client_id}`,
            }}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <p className="clientName">{this.state.job.client_name}</p>
          </Link>
          {this.state.job.job_type != "Weekends" ? (
            <p>
              Job Date:{" "}
              {moment(this.state.job.job_start_date).format(
                "ddd DD MMM - HH:mm"
              )}{" "}
              -{" "}
              {moment(this.state.job.job_end_date).format("ddd DD MMM - HH:mm")}
            </p>
          ) : (
            <div>
              <p>
                Job Starts:{" "}
                {moment(this.state.job.job_start_date).format(
                  "ddd DD MMM - HH:mm"
                )}
              </p>
              <p>
                Job Ends:{" "}
                {moment(this.state.job.job_end_date).format(
                  "ddd DD MMM - HH:mm"
                )}
              </p>
            </div>
          )}
          {this.state.job.is_repeating ? (
            <p>
              Repeat{" "}
              {this.state.job.repeat_frequency != "custom"
                ? this.state.job.repeat_frequency
                : this.state.custom_days.map((c) => c)}{" "}
              until{" "}
              {moment(this.state.job.repeat_end_date).format("ddd DD MMM")}
            </p>
          ) : null}
          <h5>Service Type</h5>
          <p>{this.state.job.job_type}</p>
          <div>
            {/* <a
              onClick={() => {
                this.setState({ editMode: true });
              }}
              style={{
                cursor: "pointer",
                position: "absolute",
                right: 20,
                top: -20,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#fff",
                width: 100,
                height: 40,
                textDecoration: "none",
                color: "#000",
              }}
            >
              <img src={require("../assets/Edit.png")} width={24} height={24} />
              <span className="editBio">Edit Job</span>
            </a> */}
            <h5>Job Description</h5>
            <p>{this.state.job.job_description}</p>
          </div>
          {/* <h5>Address</h5>
          <p>{this.state.address.address_line_1} {this.state.address.address_line_2} {this.state.address.post_code} {this.state.address.city}</p> */}
          {this.state.selected_teacher != null ? (
            <Link
              to={{
                pathname: `/teacherDetails/${this.state.selected_teacher.id}`,
              }}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <div>
                <h5>Selected Teacher</h5>
                <div className="applicantItem">
                  <img
                    src={this.state.selected_teacher.profile_image}
                    style={{
                      width: 60,
                      height: 60,
                      borderRadius: 30,
                      marginRight: 25,
                    }}
                  />
                  {this.state.selected_teacher.name}
                </div>
              </div>
            </Link>
          ) : null}
          {this.state.job.status == "cancelled" ? (
            <div>
              <h5>Cancellation Reason</h5>
              <p>{this.state.job.cancellation_note}</p>
            </div>
          ) : null}

          <h5>Applicants</h5>
          {this.state.applicants.length == 0 ? <p>No applicants yet</p> : null}
          <div style={{ height: 400, overflowY: "scroll" }}>
            {this.state.applicants.map((a) => (
              <Link
                to={{
                  pathname: `/teacherDetails/${a.teacher_id}`,
                }}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <div className="applicantItem">
                  <img
                    src={a.profile_image}
                    style={{
                      width: 60,
                      height: 60,
                      borderRadius: 30,
                      marginRight: 25,
                    }}
                  />
                  {a.name}
                </div>
              </Link>
            ))}
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            bottom: 20,
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <button
            className="saveButton cancel"
            onClick={() => {
              this.setState({ showCancellation: true });
            }}
          >
            Delete
          </button>
        </div>
        <Cancellation
          open={this.state.showCancellation}
          close={this.closeCancellation}
          deleteJob={this.deleteJob}
        />
      </div>
    );
  }
}
