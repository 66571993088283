import React, { Component } from 'react'
import UserContext from '../helper/UserContext'
import Header from './common/Header'
import {Backdrop, CircularProgress, FormControl, Select, MenuItem, TextField, Button} from '@mui/material/';
import GenerateReference from './common/GenerateReference'
import axios from 'axios'
import {Link} from 'react-router-dom'
import ReactPaginate from 'react-paginate';
import RefereneceCodeList from './common/ReferenceCodeList';

export default class Clients extends Component {
    static contextType = UserContext
    constructor(props){
        super(props)
        this.state ={
            allClients:[],
            currentClients:[],
            cancelledClients:[],
            isLoading:false,
            referenceModal:false,
            isSuccess:false,
            isFetching:false,
            message:'',
            hasError:false,
            showReferenceList:false,
            unregisteredCodes:[],
            selectedFilter:'Active',
            currentClientCount:0,
            activeClients:[],
            page:0,
            searchQuery:''
        }
    }

    async componentDidMount(){
        console.log('component did mount')
        this.setState({isLoading:this.context.isLoading})
        this.getAllClients()
    }

    getAllClients = async () =>{
        this.setState({isFetching:true})
        try{
            let url = `${process.env.REACT_APP_BASE_API_URL}/management/clients/getAllClients`
            let config = {
                headers:{
                    authorization:'Bearer ' + localStorage.getItem('token')
                }
            }
            const clients = await axios.get(url,config)
            console.log(clients.data.allClients)
            this.setState({
                allClients:clients.data.allClients,
                activeClients:clients.data.allClients.filter(c=>c.status == "Active"),
                currentClients:clients.data.allClients.filter(c=>c.status == 'Active').slice(0,10),
                isFetching:false,
                cancelledClients:clients.data.allClients.filter(c=>c.status == 'Cancelled'),
                currentClientCount:clients.data.allClients.filter(c=>c.status == 'Active').length
            })

        }catch(err){
            console.log(err)
        }
    }

    openReferenceModal= () =>{
        this.setState({
            referenceModal:!this.state.referenceModal,
            hasError:false,
            message:'',
            isSuccess:false
        })
    }
    generate = async (client_email, membershipType) =>{
        try{
            let url=`${process.env.REACT_APP_BASE_API_URL}/management/clients/generateReferenceCode`
            let params = {
                client_email,
                membershipType,
                user_type:'Client'
            }
            let config = {
                headers:{
                    authorization:'Bearer ' + localStorage.getItem('token')
                }
            }
            const generateCode = await axios.post(url,params,config)
            if(generateCode.status == 200){
                this.setState({isSuccess:true})
            }
        } catch(err){
            this.setState({
                hasError:true,
                message:err.response.data.message
            })
            console.log(err)
        }
    }
    handlePageClick = (page)=>{
        this.setState({
            currentClients:this.state.allClients.filter(c=>c.status == this.state.selectedFilter).slice((page.selected * 10), (((page.selected + 1) * 10))),
            page:page
        })
    }
    showReferenceCodes = async () =>{
        try{
            let url = `${process.env.REACT_APP_BASE_API_URL}/management/clients/getUnregisteredClients`
            let config = {
                headers:{
                    authorization:'Bearer ' + localStorage.getItem('token')
                }
            }
            let unregisteredclients = await axios.get(url,config)
            this.setState({
                showReferenceList:true,
                unregisteredCodes:unregisteredclients.data.unregisteredClients
            })
        }catch(err){
            console.log(err)
        }
    }
    closeReferenceCodeList = () =>{
        this.setState({showReferenceList:false})
    }
    handleFilter = (v) =>{
        this.setState({
            selectedFilter:v,
            currentClients:this.state.allClients.filter(c=>c.status == v).slice(0,10),
            page:0,
            currentClientCount:this.state.allClients.filter(c=>c.status == v).length
        })
    }
    search = async () =>{
        if(this.state.searchQuery == ''){return null}
        try{
            let url = `${process.env.REACT_APP_BASE_API_URL}/management/clients/searchByName`
            let config = {
                headers:{
                    authorization:'Bearer ' + localStorage.getItem('token')
                }
            }
            let params = {
                name:this.state.searchQuery
            }
            let clients = await axios.post(url,params,config)
            if(clients.status == 200){
                if(clients.data.clients.length > 0){
                    this.setState({currentClients:clients.data.clients.slice(0,10), page:0, currentClientCount:clients.data.clients.length, allClients:clients.data.clients})
                }
            }
        }catch(err){
            console.log(err)
        }
    }
    clearSearch = async () =>{
        this.setState({searchQuery:''})
        await this.getAllClients()
    }
    render() {
        return (
            this.context.isLoading ?
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={this.context.isLoading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                :
                this.state.isFetching ? 
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={this.state.isFetching}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                :
                <div className="contentContainer">
                    <Header header={'Clients'} user={this.context.user} action={this.openReferenceModal} buttonTitle={'Generate Reference Code'} showClientList={true} showList={this.showReferenceCodes}/>
                    <div className='searchContainer'>
                        <TextField onChange={(e)=>{this.setState({searchQuery:e.target.value})}} value={this.state.searchQuery} style={{flex:1, marginRight:25}} placeholder='Client Name'/>
                        <Button className='searchButton' onClick={()=>{this.search()}}>
                            <span>Search</span>
                        </Button>
                        <Button onClick={()=>{this.clearSearch()}}>
                            <span>Clear</span>
                        </Button>
                    </div>
                    <div className="table">
                        <div className="tableHeader">
                            <span style={{width:150}}></span>
                            <span style={{width:450}}>Name</span>
                            <span style={{width:200}}>Phone</span>
                            <span style={{width:200}}>Membership</span>
                            <span style={{width:200}}>Registration Status</span>
                            <span style={{width:200}}>
                            <FormControl fullWidth variant="standard" >
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={this.state.selectedFilter}

                                    onChange={(e)=>{this.handleFilter(e.target.value)}}
                                >
                                    <MenuItem value='Active'>Active</MenuItem>
                                    <MenuItem value='Cancelled'>Cancelled</MenuItem>
                                </Select>
                                </FormControl>
                            </span>
                        </div>
                        {this.state.currentClients.map(client=>
                            <Link 
                                to={{
                                    pathname: `/clientDetails/${client.client_id}`,
                                }}
                                style={{textDecoration:'none'}}
                            >
                                <div className="tableContentItem nanniesItem">
                                    <span style={{width:150}}>{client.profile_image != null ? <img src={client.profile_image} /> : <img src={require('../assets/profile.png')}/>}</span>
                                    <span className="nameContainer">{client.name}<br/><span className='email'>{client.email}</span></span>
                                    <span style={{width:200}}>{client.phone}</span>
                                    <span style={{width:200}}>{client.membershipType}</span>
                                    <span style={{width:200}}>{client.registration_status}</span>
                                    <span style={{width:200}}>{client.status}</span>
                                </div>
                            </Link>
                        )}
                        
                        <ReactPaginate
                            breakLabel="..."
                            nextLabel=">"
                            onPageChange={this.handlePageClick}
                            pageRangeDisplayed={5}
                            pageCount={Math.ceil(this.state.currentClientCount / 10)}
                            previousLabel="<"
                            renderOnZeroPageCount={null}
                            activeClassName="activePagination"
                            containerClassName='paginationContainer'
                            pageClassName='paginationItem'
                            nextClassName='nextButton'
                            previousClassName='nextButton'
                            breakClassName='paginationItem'
                            forcePage={0}
                        />
                    </div>
                    <GenerateReference open={this.state.referenceModal} generate={this.generate} close={this.openReferenceModal} isSuccess={this.state.isSuccess} message={this.state.message} hasError={this.state.hasError}/>
                    <RefereneceCodeList open={this.state.showReferenceList} close={this.closeReferenceCodeList} codeList={this.state.unregisteredCodes}/>
                </div>
        )
    }
}
