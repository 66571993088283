import React, { useContext, useEffect, useState } from 'react'
import { Link, useHistory, } from 'react-router-dom'
import UserContext from '../helper/UserContext'
import axios from 'axios'
import { Backdrop, CircularProgress } from '@mui/material/';
import { LocalConvenienceStoreOutlined } from '@mui/icons-material';

export default function Navigation() {

    const { user, updateUser, isStillLoading } = useContext(UserContext)
    const [activeLink, setActiveLink] = useState()
    const [isLoggingOut, setisLoggingOut] = useState(false)
    useEffect(() => {
        async function fetchUser() {
            try {

                isStillLoading(true)
                if (user.length == 0) {
                    let token = localStorage.getItem('token')
                    if (token) {
                        let url = `${process.env.REACT_APP_BASE_API_URL}/users/me`
                        let config = {
                            headers: {
                                authorization: 'Bearer ' + localStorage.getItem('token')
                            }
                        }
                        const user = await axios.get(url, config)
                        if (user.status == 200) {
                            let _user = {
                                id: user.data.user.userID,
                                user_type: user.data.user.userType,
                                name: user.data.user.name
                            }
                            updateUser(_user)
                            if (_user.user_type == 'Admin') {
                                setActiveLink('Clients')
                            } else {
                                console.log('token is invalid')
                                localStorage.removeItem('token')
                                history.push('/Login')
                            }
                            isStillLoading(false)
                        } else {
                            console.log('token is invalid')
                            localStorage.removeItem('token')
                            history.push('/Login')
                        }
                    } else {
                        console.log('no token')
                        //history.push('/Login')
                    }
                } else {
                    //console.log('acxzc')
                    isStillLoading(false)
                }
            } catch (err) {
                console.log(err)
                localStorage.removeItem('token')
                localStorage.removeItem('user_type')
                history.push('/Login')
            }
        }
        fetchUser()
    }, [])
    let history = useHistory()

    const navigate = (path) => {
        history.push('/' + path)
        setActiveLink(path)
    }

    const logout = () => {
        setisLoggingOut(true)
        setTimeout(() => {
            setisLoggingOut(false)
            localStorage.removeItem('token')
            localStorage.removeItem('user_type')
            history.push('/Login')
        }, 1000);
    }

    return (
        user && user.name && user.name != '' ?
            <div className="navComponent">
                <h3>The Rocket House</h3>
                {user.user_type == 'Admin' ?
                    <div className="navElementHolder">
                        <Link to="#" className={`navElement ${activeLink == 'Clients' ? "active" : ''}`} onClick={() => { navigate('Clients') }}>
                            <img src={activeLink == 'Clients' ? require('../assets/dashboard_active.png') : require('../assets/dashboard.png')} />
                            Clients
                        </Link>
                        <Link to="#" className={`navElement ${activeLink == 'Bookings' ? "active" : ''}`} onClick={() => { navigate('Bookings') }}>
                            <img src={activeLink == 'Bookings' ? require('../assets/dashboard_active.png') : require('../assets/dashboard.png')} />
                            Bookings
                        </Link>
                        <Link to="#" className={`navElement ${activeLink == 'Jobs' ? "active" : ''}`} onClick={() => { navigate('Jobs') }}>
                            <img src={activeLink == 'Jobs' ? require('../assets/dashboard_active.png') : require('../assets/dashboard.png')} />
                            Jobs
                        </Link>
                        <Link to="#" className={`navElement ${activeLink == 'Activities' ? "active" : ''}`} onClick={() => { navigate('Activities') }}>
                            <img src={activeLink == 'Activities' ? require('../assets/dashboard_active.png') : require('../assets/dashboard.png')} />
                            Activities
                        </Link>
                        <Link to="#" className={`navElement ${activeLink == 'Services' ? "active" : ''}`} onClick={() => { navigate('Services') }}>
                            <img src={activeLink == 'Services' ? require('../assets/dashboard_active.png') : require('../assets/dashboard.png')} />
                            Services
                        </Link>
                        {/* <Link to="#" className={`navElement ${activeLink == 'Skills' ? "active" : ''}`} onClick={()=>{navigate('Skills')}}>
                        <img src={activeLink == 'Skills' ? require('../assets/dashboard_active.png') : require('../assets/dashboard.png')}/>
                        Skills
                    </Link> */}
                        <Link to="#" className={`navElement ${activeLink == 'Teachers' ? "active" : ''}`} onClick={() => { navigate('Teachers') }}>
                            <img src={activeLink == 'Teachers' ? require('../assets/dashboard_active.png') : require('../assets/dashboard.png')} />
                            Teachers
                        </Link>
                        <Link to="#" className={`navElement ${activeLink == 'Notifications' ? "active" : ''}`} onClick={() => { navigate('Notifications') }}>
                            <img src={activeLink == 'Notifications' ? require('../assets/dashboard_active.png') : require('../assets/dashboard.png')} />
                            Notifications
                        </Link>
                        <Link to="#" className={`navElement ${activeLink == 'Reviews' ? "active" : ''}`} onClick={() => { navigate('Reviews') }}>
                            <img src={activeLink == 'Reviews' ? require('../assets/dashboard_active.png') : require('../assets/dashboard.png')} />
                            Reviews
                        </Link>
                        {/* <Link to="#" className={`navElement ${activeLink == 'Reviews' ? "active" : ''}`} onClick={()=>{navigate('Reviews')}}>
                        <img src={activeLink == 'Reviews' ? require('../assets/dashboard_active.png') : require('../assets/dashboard.png')}/>
                        Reviews
                    </Link> */}



                    </div>
                    :
                    <div className="navElementHolder">
                        <Link to="#" className={`navElement ${activeLink == 'Bookings' ? "active" : ''}`} onClick={() => { navigate('Bookings') }}>
                            <img src={activeLink == 'Bookings' ? require('../assets/dashboard_active.png') : require('../assets/dashboard.png')} />
                            Bookings
                        </Link>
                        <Link to="#" className={`navElement ${activeLink == 'Nannies' ? "active" : ''}`} onClick={() => { navigate('Availability') }}>
                            <img src={activeLink == 'Availability' ? require('../assets/dashboard_active.png') : require('../assets/dashboard.png')} />
                            Availability
                        </Link>
                    </div>
                }
                <div className="navBottom">
                    <Link to={user.user_type == 'Nanny' ? '/EditNannyProfile' : '#'} className={`navElement ${activeLink == 'Settings' ? "active" : ''}`} onClick={() => { navigate('Settings') }} /* style={{display:user.user_type == 'Admin' ? 'none' : 'flex'}} */>
                        <img src={activeLink == 'Settings' ? require('../assets/settings_active.png') : require('../assets/settings.png')} />
                        Settings
                    </Link>
                    <Link to="#" className={`navElement`} onClick={() => { logout() }}>
                        <img src={require('../assets/dashboard.png')} />
                        Logout
                    </Link>
                </div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={isLoggingOut}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div> : null
    )
}
