import {createContext, useState} from 'react'
const UserContext = createContext()



export function UserProvider({children}){

    const [user, setUser] = useState([])
    const [isLoading, setIsLoading] = useState()
    const updateUser = (user) => {
        setUser(user)
    }
    const isStillLoading = (_load) =>{
        setIsLoading(_load)
    }
    return(
        <UserContext.Provider value={{user, updateUser, isLoading, isStillLoading}}>
            {children}
        </UserContext.Provider>
    )
}

export default UserContext;