import React, { Component } from "react";
import UserContext from "../helper/UserContext";
import Header from "./common/Header";
import {
  Backdrop,
  CircularProgress,
  Select,
  MenuItem,
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material/";
import GenerateReference from "./common/GenerateReference";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from "moment";
import ReactPaginate from "react-paginate";

class Bookings extends Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      bookings: [],
      cancelledBookings: [],
      approvedBookings: [],
      pastBookings: [],
      awaitingBookings: [],
      isLoading: false,
      referenceModal: false,
      isSuccess: false,
      isFetching: false,
      selectedFilter: "Awaiting",
      activeList: [],
      currentList: [],
      page: 0,
      activeTab: "awaiting",
    };
  }

  async componentDidMount() {
    console.log("component did mount");
    if (localStorage.getItem("user_type") == "Nanny") {
      if (this.fetchMyProfile()) {
        this.getMyBookings();
      }
    } else {
      this.getAllBookings();
    }
    this.setState({ isLoading: this.context.isLoading });
  }
  fetchMyProfile = async () => {
    this.setState({ isFetching: true });
    let url = `${process.env.REACT_APP_BASE_API_URL}/nannies/me`;
    let config = {
      headers: {
        authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    const me = await axios.get(url, config);
    if (!me.data.me.approved) {
      this.setState({
        profileNotApproved: true,
        isFetching: false,
      });
      return false;
    } else {
      return true;
    }
  };
  getAllBookings = async () => {
    this.setState({ isFetching: true });
    let url = "";
    try {
      url = `${process.env.REACT_APP_BASE_API_URL}/management/bookings/getAllBookings`;
      let config = {
        headers: {
          authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      const bookings = await axios.get(url, config);
      console.log(bookings);
      if (bookings.data.bookingCount > 0) {
        this.setState({
          bookings: bookings.data.allBookings.sort(
            (a, b) => new Date(b.created_at) - new Date(a.created_at)
          ),
          cancelledBookings: bookings.data.allBookings.filter(
            (b) => b.status == "Cancelled"
          ),
          awaitingBookings: bookings.data.allBookings.filter(
            (b) => b.status == "Awaiting confirmation"
          ),
          approvedBookings: bookings.data.allBookings.filter(
            (b) => b.status == "Approved"
          ),
          pastBookings: bookings.data.allBookings.filter((b) =>
            moment(b.startTime).isBefore(new Date())
          ),
          bookingCount: bookings.data.bookingCount,
          activeList: bookings.data.allBookings.filter(
            (b) => b.status == "Awaiting confirmation"
          ),
          currentList: bookings.data.allBookings
            .filter((b) => b.status == "Awaiting confirmation")
            .slice(0, 10),
          isFetching: false,
        });
      } else {
        this.setState({ isFetching: false });
      }
    } catch (err) {
      console.log(err);
    }
  };
  getMyBookings = async () => {
    this.setState({ isFetching: true });
    let url = "";
    try {
      url = `${process.env.REACT_APP_BASE_API_URL}/nannies/myBookings`;
      let config = {
        headers: {
          authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      const bookings = await axios.get(url, config);
      console.log(bookings);
      if (bookings.data.bookingCount > 0) {
        this.setState({
          bookings: bookings.data.myBookings,
          cancelledBookings: bookings.data.myBookings.filter(
            (b) => b.status == "Cancelled"
          ),
          awaitingBookings: bookings.data.myBookings.filter(
            (b) => b.status == "Awaiting confirmation"
          ),
          approvedBookings: bookings.data.myBookings.filter(
            (b) => b.status == "Approved"
          ),
          pastBookings: bookings.data.myBookings.filter((b) =>
            moment(b.startTime).isBefore(new Date())
          ),
          bookingCount: bookings.data.bookingCount,
          activeList: bookings.data.myBookings.filter(
            (b) => b.status == "Awaiting confirmation"
          ),
          isFetching: false,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  openReferenceModal = () => {
    this.setState({
      referenceModal: !this.state.referenceModal,
    });
  };
  generate = async (client_email) => {
    let url = `${process.env.REACT_APP_BASE_API_URL}/management/clients/generateReferenceCode`;
    let params = {
      client_email,
    };
    let config = {
      headers: {
        authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    const generateCode = await axios.post(url, params, config);
    if (generateCode.status == 200) {
      this.setState({ isSuccess: true });
    }
  };
  handleFilter = (v) => {
    this.setState({
      selectedFilter: v,
      activeList:
        v == "Approved"
          ? this.state.approvedBookings
          : v == "Cancelled"
          ? this.state.cancelledBookings
          : this.state.awaitingBookings,
      currentList:
        v == "Approved"
          ? this.state.approvedBookings.slice(0, 10)
          : v == "Cancelled"
          ? this.state.cancelledBookings.slice(0, 10)
          : this.state.awaitingBookings.slice(0, 10),
      page: 0,
    });
  };
  handlePageClick = (page) => {
    this.setState({
      currentList: this.state.activeList.slice(
        page.selected * 10,
        (page.selected + 1) * 10
      ),
      page: page.selected,
    });
  };
  getDuration = (_start, _end) => {
    let end = moment(_end);
    let start = moment(_start);
    let _diff = end.diff(start, "hours", true);
    let str = _diff.toString();
    if (str.length > 3) _diff = _diff.toFixed(1);
    return _diff;
  };
  changeData = (tab) => {
    this.setState({ activeTab: tab });
    this.setState({
      selectedFilter: tab,
      activeList:
        tab == "approved"
          ? this.state.approvedBookings
          : tab == "cancel"
          ? this.state.cancelledBookings
          : this.state.awaitingBookings,
      currentList:
        tab == "approved"
          ? this.state.approvedBookings.slice(0, 10)
          : tab == "cancel"
          ? this.state.cancelledBookings.slice(0, 10)
          : this.state.awaitingBookings.slice(0, 10),
      page: 0,
    });
  };
  goToDetail = (id) => {
    this.props.history.push(`/bookingDetails/${id}`);
  };
  render() {
    console.log(this.state.approvedBookings);
    return this.context.isLoading ? (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={this.context.isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    ) : this.state.isFetching ? (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={this.state.isFetching}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    ) : !this.state.profileNotApproved ? (
      <div className="contentContainer">
        <Header
          header={"Bookings"}
          user={this.context.user}
          openModal={this.openReferenceModal}
          buttonTitle={"Generate Reference Code"}
        />
        <div className="clientDetailContainer">
          <div className="tabs">
            <div
              className={`tab ${
                this.state.activeTab == "awaiting" ? "-activeTab" : ""
              }`}
              onClick={() => {
                this.changeData("awaiting");
              }}
            >
              Awaiting Confirmation
            </div>
            <div
              className={`tab ${
                this.state.activeTab == "approved" ? "-activeTab" : ""
              }`}
              onClick={() => {
                this.changeData("approved");
              }}
            >
              Approved
            </div>
            <div
              className={`tab ${
                this.state.activeTab == "cancel" ? "-activeTab" : ""
              }`}
              onClick={() => {
                this.changeData("cancel");
              }}
            >
              Cancelled
            </div>
          </div>
          <TableContainer className="notificationTableContainer">
            <Table>
              <TableHead className="header">
                <TableRow>
                  <TableCell className="cell">Client Name</TableCell>
                  <TableCell className="cell">Created At</TableCell>
                  <TableCell className="cell">Booking Details</TableCell>
                  <TableCell className="cell">Nanny Name</TableCell>
                  <TableCell className="cell">Status</TableCell>
                  <TableCell className="cell">Has Connection?</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="content">
                {this.state.bookingCount > 0
                  ? this.state.currentList.map((b) => (
                      <TableRow
                        onClick={() => {
                          this.goToDetail(b.booking_id);
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <TableCell className="cell">{b.client_name}</TableCell>
                        <TableCell className="cell">
                          {moment(b.created_at).format("DD MMMM - HH:mm")}
                        </TableCell>
                        <TableCell className="cell">
                          {b.service_type}
                          <br />
                          {moment(b.startTime).format("ll")} /{" "}
                          {this.getDuration(b.startTime, b.endTime)} hours
                        </TableCell>
                        <TableCell className="cell">{b.nanny_name}</TableCell>
                        <TableCell className="cell">{b.status}</TableCell>
                        <TableCell className="cell">
                          {b.has_contact ? "Yes" : "No"}
                        </TableCell>
                      </TableRow>
                    ))
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <ReactPaginate
          breakLabel="..."
          nextLabel=">"
          onPageChange={this.handlePageClick}
          pageRangeDisplayed={5}
          pageCount={Math.ceil(this.state.activeList.length / 10)}
          previousLabel="<"
          renderOnZeroPageCount={null}
          activeClassName="activePagination"
          activeLinkClassName="activePagination"
          containerClassName="paginationContainer"
          pageClassName="paginationItem"
          pageLinkClassName="paginationItem"
          nextClassName="nextButton"
          nextLinkClassName="nextButton"
          previousClassName="nextButton"
          previousLinkClassName="nextButton"
          breakClassName="paginationItem"
          forcePage={this.state.page}
        />
        <GenerateReference
          open={this.state.referenceModal}
          generate={this.generate}
          close={this.openReferenceModal}
          isSuccess={this.state.isSuccess}
        />
      </div>
    ) : (
      <div className="contentContainer">
        <Header
          header={"Bookings"}
          user={this.context.user}
          openModal={this.openReferenceModal}
          buttonTitle={"Generate Reference Code"}
        />
        <p>
          After your profile approved, you can manage your bookings on this
          page.
        </p>
      </div>
    );
  }
}
export default Bookings;
