import React, { Component } from 'react'
import axios from 'axios'
import UserContext from '../helper/UserContext'
import queryString from 'query-string'

export default class Registration extends Component {
    static contextType = UserContext
    constructor(props){
        super(props)
        this.state={
            registration_code:'',
            email:'',
            password:'',
            password_confirm:'',
            user_type:''
        }
    }
    componentDidMount = async () =>{
        let {registration_code, email, user_type} = queryString.parse(window.location.search)
        console.log(registration_code, email, user_type)
        this.setState({
            registration_code:registration_code,
            email:email,
            user_type:user_type
        })
    }
    handleText=(e)=>{
        this.setState({
            [e.target.name]:e.target.value
        })
    }
    registerUser = async ()=>{
        console.log(this.state)
        try{
            if(this.state.registration_code == '' || this.state.email =='' || this.state.password =='' || this.state.password_confirm =='' || this.state.user_type ==''){return null}
            let url =`${process.env.REACT_APP_BASE_API_URL}/register`
            let params = {
                registration_code:this.state.registration_code,
                email:this.state.email,
                password:this.state.password,
                user_type:this.state.user_type
            }
            const register = await axios.post(url,params)
            console.log(register)
            if(register.status == 200){
                localStorage.setItem("token", register.data.token)
                let url = `${process.env.REACT_APP_BASE_API_URL}/users/me`
                let config = {
                    headers:{
                        authorization:'Bearer ' + register.data.token
                    }
                }
                const user = await axios.get(url,config)
                console.log(user)
                let _user = {
                    id:user.data.user.userID,
                    user_type:user.data.user.userType,
                    name:user.data.user.name,
                    email:this.state.email
                }
                localStorage.setItem('user_type',user.data.user.userType)
                this.context.updateUser(_user)
                if (user.data.user.userType == 'Admin') {
                    this.props.history.push('/Clients')
                } else {
                    user.data.user.profile_completed  ? this.props.history.push('/Bookings') : this.props.history.push('/EditNannyProfile')
                }
            }
        }catch(err){
            console.log(err)
        }
    }
  render() {
    return (
        <div className="login">
        <h1>The Rocket House</h1>
        <div className="loginContainer">
            <input type="text" name="registration_code" id='registration_code' placeholder="registration_code" onChange={(e)=>{this.handleText(e)}} className="loginInput" value={this.state.registration_code}/>
            <input type="text" name="email" id='email' placeholder="email" onChange={(e)=>{this.handleText(e)}} className="loginInput" value={this.state.email}/>
            <input type="password" name="password" id='password' placeholder="password" onChange={(e)=>{this.handleText(e)}} className="loginInput"/>
            <input type="password" name="password_confirm" id='password_confirm' placeholder="password_confirm" onChange={(e)=>{this.handleText(e)}} className="loginInput"/>
            <button type="submit" name="login" id="login" className="loginButton" onClick={()=>{this.registerUser()}}>Register</button>
        </div>
    </div>
    )
  }
}
