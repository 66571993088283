import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function MembershipModal(props) {
  const { open, change, close, isSuccess, hasError, message, currentMembership } = props;

  const [membershipType, setMembershipType] = React.useState(currentMembership);

  const handleChange = (event) => {
    setMembershipType(event.target.value);
  };

  return (
    <div>
      <Dialog open={open} onClose={close}>
        <DialogTitle>Change Membership Type</DialogTitle>
        {isSuccess ? (
          <DialogContent>
            <DialogContentText>
              The client's membership type has been changed.
            </DialogContentText>
          </DialogContent>
        ) : (
          <DialogContent>
            <DialogContentText style={{height:25}}>
   
            </DialogContentText>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Membership Type
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={membershipType}
                label="Membership Type"
                onChange={handleChange}
              >
                <MenuItem value="Basic">Basic</MenuItem>
                <MenuItem value="Premium">Premium</MenuItem>
              </Select>
            </FormControl>
          </DialogContent>
        )}
        {isSuccess ? (
          <DialogActions>
            <button type="submit" onClick={close} className="buttonMain">
              <span>Close</span>
            </button>
          </DialogActions>
        ) : (
          <div>
            {hasError? <span className="errorMessage">{message}</span> : null }
            <DialogActions>
              <button
                type="submit"
                onClick={() => {
                  change(membershipType);
                }}
                className="buttonMain"
              >
                <span>Update</span>
              </button>
            </DialogActions>
          </div>
        )}
      </Dialog>
    </div>
  );
}
