import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function GenerateReference(props) {
  const { open, generate, close, isSuccess, hasError, message } = props;
  const [email, setEmail] = useState();

  const [membershipType, setMembershipType] = React.useState("Basic");

  const handleChange = (event) => {
    setMembershipType(event.target.value);
  };

  return (
    <div>
      <Dialog open={open} onClose={close}>
        <DialogTitle>Generate Reference Code</DialogTitle>
        {isSuccess ? (
          <DialogContent>
            <DialogContentText>
              The code has been sent to the user's email.
            </DialogContentText>
          </DialogContent>
        ) : (
          <DialogContent>
            <DialogContentText>
              The code will be sent to the user's email.
            </DialogContentText>
            <input
              type="text"
              className="generate"
              name="email"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Membership Type
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={membershipType}
                label="Membership Type"
                onChange={handleChange}
              >
                <MenuItem value="Basic">Basic</MenuItem>
                <MenuItem value="Premium">Premium</MenuItem>
              </Select>
            </FormControl>
          </DialogContent>
        )}
        {isSuccess ? (
          <DialogActions>
            <button type="submit" onClick={close} className="buttonMain">
              <span>Close</span>
            </button>
          </DialogActions>
        ) : (
          <div>
            {hasError? <span className="errorMessage">{message}</span> : null }
            <DialogActions>
              <button
                type="submit"
                onClick={() => {
                  generate(email, membershipType);
                }}
                className="buttonMain"
              >
                <span>Generate</span>
              </button>
            </DialogActions>
          </div>
        )}
      </Dialog>
    </div>
  );
}
