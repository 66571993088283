import React, { Component } from "react";
import UserContext from "../../helper/UserContext";
import Header from "../common/Header";
import {
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  CircularProgress,
  Select,
  MenuItem,
  Autocomplete,
  Box,
  Input,
} from "@mui/material";
import axios from "axios";
import Message from "../common/Message";
import moment from "moment";

class NewNotification extends Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      notifications: [],
      activeTab: "newnotification",
      totalUserCount: 0,
      teacherCount: 0,
      familyCount: 0,
      title: "",
      message: "",
      testUsers: [],
      users: [],
      teachersSelected: false,
      clientsSelected: false,
      isTesting: false,
      notification_type: "",
      externalLink: "",
      buttonText: "",
      teacher: null,
      teachers: [],
      imagePath: null,
      imageUploading: false,
    };
  }
  componentDidMount() {
    this.getAllUsers();
    this.getTesters();
    this.getTeachers();
  }
  getAllUsers = async () => {
    try {
      let url = `${process.env.REACT_APP_BASE_API_URL}/management/notifications/getUsers`;
      let config = {
        headers: {
          authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      let users = await axios.get(url, config);
      if (users.status == 200) {
        this.setState({
          familyCount: users.data.users.filter((u) => u.userType == "Client")
            .length,
          teacherCount: users.data.users.filter((u) => u.userType == "Nanny")
            .length,
          users: users.data.users,
        });
      }
      console.log(users);
    } catch (err) {
      console.log(err);
    }
  };
  getTesters = async () => {
    try {
      let url = `${process.env.REACT_APP_BASE_API_URL}/management/settings/getTestUsers`;
      let config = {
        headers: {
          authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      let testers = await axios.get(url, config);
      console.log(testers);
      if (testers.status == 200) {
        this.setState({
          testUsers: testers.data.testers,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
  getTeachers = async () => {
    try {
      let url = `${process.env.REACT_APP_BASE_API_URL}/management/notifications/getTeachers`;
      let config = {
        headers: {
          authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      let teachers = await axios.get(url, config);
      console.log(teachers);
      this.setState({ teachers: teachers.data.teachers });
    } catch (err) {
      console.log(err);
    }
  };
  handleUsers = (type, stat) => {
    if (type == "family") {
      if (stat) {
        this.setState({
          totalUserCount: this.state.totalUserCount + this.state.familyCount,
          clientsSelected: true,
        });
      } else {
        this.setState({
          totalUserCount: this.state.totalUserCount - this.state.familyCount,
          clientsSelected: false,
        });
      }
    } else if (type == "teacher") {
      if (stat) {
        this.setState({
          totalUserCount: this.state.totalUserCount + this.state.teacherCount,
          teachersSelected: true,
        });
      } else {
        this.setState({
          totalUserCount: this.state.totalUserCount - this.state.teacherCount,
          teachersSelected: false,
        });
      }
    }
  };
  sendTestNotification = async () => {
    try {
      if (this.state.message == "" || this.state.notification_type == "") {
        return null;
      }
      let url = `${process.env.REACT_APP_BASE_API_URL}/management/notifications/sendTestNotification`;
      let config = {
        headers: {
          authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      let params = {
        title: this.state.title || "The Rocket House",
        messageBody: this.state.message,
        send_to_client: this.state.clientsSelected,
        send_to_teacher: this.state.teachersSelected,
        longText: this.state.detailText,
        notification_type: this.state.notification_type,
        url: this.state.externalLink,
        button_text: this.state.buttonText,
        image_url: this.state.imagePath,
      };
      if (this.state.notification_type == "Promoting") {
        params.teacher_id = this.state.teacher.nanny_id;
        params.send_to_client = true;
      }

      let notification = await axios.post(url, params, config);
      if (notification.status == 200) {
        this.setState({
          showMessage: true,
          resultMessage: "Notification has been sent to users.",
          isTesting: false,
        });
        await this.getAllNotifications();
      }
    } catch (err) {
      console.log(err);
    }
  };
  sendNotification = async () => {
    try {
      if (this.state.message == "" || this.state.notification_type == "") {
        return null;
      }
      let url = `${process.env.REACT_APP_BASE_API_URL}/management/notifications/sendNotification`;
      let config = {
        headers: {
          authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      let params = {
        title: this.state.title || "The Rocket House",
        messageBody: this.state.message,
        send_to_client: this.state.clientsSelected,
        send_to_teacher: this.state.teachersSelected,
        longText: this.state.detailText,
        notification_type: this.state.notification_type,
        url: this.state.externalLink,
        button_text: this.state.buttonText,
        image_url: this.state.imagePath,
      };
      if (this.state.notification_type == "Promoting") {
        params.teacher_id = this.state.teacher.nanny_id;
        params.send_to_client = true;
      }

      let notification = await axios.post(url, params, config);
      if (notification.status == 200) {
        this.setState({
          showMessage: true,
          resultMessage: "Notification has been sent to users.",
          isTesting: false,
        });
        await this.getAllNotifications();
      }
    } catch (err) {
      console.log(err);
    }
  };
  closeMessage = () => {
    if (this.state.isTesting) {
      this.setState({
        showMessage: false,
        resultMessage: "",
      });
    } else {
      this.setState({
        showMessage: false,
        title: "",
        message: "",
        teachersSelected: false,
        clientsSelected: false,
        totalUserCount: 0,
        detailText: "",
        notification_type: "",
        externalLink: "",
        buttonText: "",
        imagePath: null,
        imageUploading: false,
      });
    }
  };
  uploadImage = async (e) => {
    try {
      console.log(e.target.files[0]);
      this.setState({ imageUploading: true });
      let url = `${process.env.REACT_APP_BASE_API_URL}/management/notifications/uploadImage`;
      let config = {
        headers: {
          authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      let image = e.target.files[0];

      let params = new FormData();
      params.append("notification_image", image);
      let upload = await axios.post(url, params, config);
      console.log(upload);
      if (upload.status == 200) {
        this.setState({
          imageUploading: false,
          imagePath: upload.data.file.location,
        });
      }
    } catch (err) {
      this.setState({
        imageUploading: false,
        imagePath: null,
      });
      console.log(err);
    }
  };
  render() {
    return (
      <div className="notificationContainer">
        <div
          className={`newNotification ${
            this.state.activeTab == "newnotification" ? "showTab" : ""
          }`}
          id="newNotification"
        >
          <div className="notificationInputBox">
            <Select
              fullWidth
              value={this.state.notification_type}
              onChange={(e) => {
                this.setState({ notification_type: e.target.value });
              }}
              displayEmpty
              style={{ marginBottom: 20 }}
            >
              <MenuItem value={""}>Notification Type</MenuItem>
              <MenuItem value={"Announcement"}>Announcement</MenuItem>
              <MenuItem value={"Update"}>New Feature Announcement</MenuItem>
              <MenuItem value={"Promoting"}>Teacher Promoting</MenuItem>
            </Select>
            {this.state.notification_type != "" ? (
              <div>
                <TextField
                  id="title"
                  value={this.state.title}
                  label="Title"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  style={{ marginBottom: 25 }}
                  onChange={(e) => {
                    this.setState({ title: e.target.value });
                  }}
                  helperText="Default title is The Rocket House. If you don't want to change the title leave this input empty."
                />
                <TextField
                  id="message"
                  label="Message"
                  fullWidth
                  value={this.state.message}
                  InputLabelProps={{ shrink: true }}
                  multiline
                  rows={4}
                  onChange={(e) => {
                    this.setState({ message: e.target.value });
                  }}
                  helperText={`Maximum character limit is ${this.state.message.length}/175 `}
                  inputProps={{ maxLength: 175 }}
                />
                <TextField
                  id="detailText"
                  label="Detail Text"
                  fullWidth
                  value={this.state.detailText}
                  InputLabelProps={{ shrink: true }}
                  multiline
                  rows={6}
                  onChange={(e) => {
                    this.setState({ detailText: e.target.value });
                  }}
                  style={{ marginTop: 25 }}
                />
                {this.state.notification_type == "Promoting" ? (
                  <Autocomplete
                    id="teacher"
                    fullWidth
                    options={this.state.teachers}
                    style={{ marginTop: 25, marginBottom: 25 }}
                    value={this.state.teacher}
                    autoHighlight
                    getOptionLabel={(option) => option.name}
                    onChange={(e, option) => {
                      this.setState({ teacher: option });
                    }}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        <img
                          src={option.profile_image}
                          style={{ width: 50, height: 50, borderRadius: 25 }}
                        />
                        {option.name}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField {...params} label="Choose a teacher" />
                    )}
                  />
                ) : null}
                {this.state.notification_type == "Announcement" ? (
                  <div style={{ marginTop: 25 }}>
                    <TextField
                      id="externalLink"
                      value={this.state.externalLink}
                      label="External Link"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      style={{ marginBottom: 25 }}
                      onChange={(e) => {
                        this.setState({ externalLink: e.target.value });
                      }}
                      helperText="Leave empty if you don't want to show action button"
                    />
                    <TextField
                      id="buttonText"
                      value={this.state.buttonText}
                      label="Button Text"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      style={{ marginBottom: 25 }}
                      onChange={(e) => {
                        this.setState({ buttonText: e.target.value });
                      }}
                      helperText="Leave empty if you don't want to show action button"
                    />
                    <div className="imageContainer">
                      <span className="title">Image</span>
                      <Input
                        type="file"
                        onChange={(e) => {
                          this.uploadImage(e);
                        }}
                      />
                      {this.state.imageUploading ? <CircularProgress /> : null}
                      {this.state.imagePath != null ? (
                        <img
                          src={this.state.imagePath}
                          style={{ height: 250 }}
                        />
                      ) : null}
                    </div>
                  </div>
                ) : null}
                {this.state.notification_type != "Promoting" ? (
                  <div className="checkboxContainer">
                    <span className="title">User Group</span>
                    <div className="checkboxes">
                      <FormControlLabel
                        required
                        control={
                          <Checkbox
                            onChange={(e) => {
                              this.handleUsers("family", e.target.checked);
                            }}
                          />
                        }
                        label="Family"
                        checked={this.state.clientsSelected}
                      />
                      <FormControlLabel
                        required
                        control={
                          <Checkbox
                            onChange={(e) => {
                              this.handleUsers("teacher", e.target.checked);
                            }}
                          />
                        }
                        label="Teacher"
                        checked={this.state.teachersSelected}
                      />
                    </div>
                    <div>Total Users: {this.state.totalUserCount}</div>
                  </div>
                ) : null}
                <div>
                  <Button
                    onClick={() => {
                      this.sendTestNotification();
                    }}
                    className="notificationTestButton"
                  >
                    Send Test Notification
                  </Button>
                  <Button
                    onClick={() => {
                      this.sendNotification();
                    }}
                    className="notificationSendButton"
                  >
                    Send Notification
                  </Button>
                </div>
              </div>
            ) : null}
          </div>
          <div className="preview">
            <div className="notificationBox">
              <img src={require("../../assets/logo.png")} />
              <div className="notificationContent">
                <span className="title">
                  {this.state.title || "The Rocket House"}
                </span>
                <span className="message">
                  {this.state.message ||
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam interdum feugiat tortor, vel sodales risus vehicula scelerisque. Integer rutrum velit vel scelerisque orci aliquam."}
                </span>
              </div>
            </div>
          </div>
        </div>
        <Message
          message={this.state.resultMessage}
          open={this.state.showMessage}
          close={this.closeMessage}
        />
      </div>
    );
  }
}

export default NewNotification;
