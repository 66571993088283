import React, { Component } from "react";
import UserContext from "../helper/UserContext";
import Header from "./common/Header";
import {
  Backdrop,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Drawer,
} from "@mui/material/";
import GenerateReference from "./common/GenerateReference";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from "moment";
import ReactPaginate from "react-paginate";
import { ArrowForwardIosOutlined } from "@mui/icons-material";
import JobDetail from "./JobDetail";


export default class Jobs extends Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "open",
      jobs: [],
      openJobs: [],
      upcomingJobs: [],
      pastJobs: [],
      cancelledJobs: [],
      currentJobs: [],
      activeList: [],
      page: 0,
      showDetail: false,
      selectedJob: {},
    };
  }

  /* .filter(
            (j) =>
              j.status == "open" && moment(j.job_start_date).isAfter(new Date())
          ) */

  componentDidMount() {
    this.getAllJobs();
  }
  getAllJobs = async () => {
    try {
      let url = `${process.env.REACT_APP_BASE_API_URL}/management/jobs/getAllJobs`;
      let config = {
        headers: {
          authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      const jobs = await axios.get(url, config);
      if (jobs.status == 200) {
        this.setState({
          jobs: jobs.data.jobs,
          currentJobs: jobs.data.jobs
            .filter(
              (j) =>
                j.status == "open" &&
                (moment(j.job_start_date).isAfter(new Date()) || moment(j.repeat_end_date).isAfter(new Date()))
            )
            .slice(0, 10),
          activeList: jobs.data.jobs.filter(
            (j) =>
              j.status == "open" && (moment(j.job_start_date).isAfter(new Date()) || moment(j.repeat_end_date).isAfter(new Date()))
          ),
          openJobs: jobs.data.jobs.filter(
            (j) =>
              j.status == "open" && (moment(j.job_start_date).isAfter(new Date()) || moment(j.repeat_end_date).isAfter(new Date()))
          ),
          upcomingJobs: jobs.data.jobs.filter(
            (j) =>
              j.status == "closed" &&
              (moment(j.job_start_date).isAfter(new Date()) || moment(j.repeat_end_date).isAfter(new Date()))
          ),
          pastJobs: jobs.data.jobs.filter(
            (j) =>
              j.status == "closed" &&
              (moment(j.job_start_date).isBefore(new Date()) || moment(j.repeat_end_date).isBefore(new Date()))
          ),
          cancelledJobs: jobs.data.jobs.filter((j) => j.status == "cancelled"),
        });
      }
      console.log(jobs);
    } catch (err) {
      console.log(err);
    }
  };
  changeData = (tab) => {
    this.setState({ activeTab: tab });
    console.log(this.state.jobs);
    if (tab == "open") {
      this.setState({
        activeList: this.state.openJobs,
        currentJobs: this.state.openJobs.slice(0, 10),
        page: 0,
      });
    } else if (tab == "upcoming") {
      this.setState({
        activeList: this.state.upcomingJobs,
        currentJobs: this.state.upcomingJobs.slice(0, 10),
        page: 0,
      });
    } else if (tab == "past") {
      console.log(this.state.pastJobs);
      this.setState({
        activeList: this.state.pastJobs,
        currentJobs: this.state.pastJobs.slice(0, 10),
        page: 0,
      });
    } else if (tab == "cancelled") {
      console.log(this.state.cancelledJobs);
      this.setState({
        activeList: this.state.cancelledJobs,
        currentJobs: this.state.cancelledJobs.slice(0, 10),
        page: 0,
      });
    }
  };
  handlePageClick = (page) => {
    this.setState({
      currentJobs: this.state.activeList.slice(
        page.selected * 10,
        (page.selected + 1) * 10
      ),
      page: page.selected,
    });
  };
  showJobDetail = async (_id) => {
    try {
      let url = `${process.env.REACT_APP_BASE_API_URL}/management/jobs/getJob?job_id=${_id}`;
      let config = {
        headers: {
          authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      let jobDetail = await axios.get(url, config);
      console.log(jobDetail);
      if (jobDetail.status == 200) {
        this.setState({
          selectedJob: jobDetail.data.job[0],
          showDetail: true,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  deleteJob = async (reason) =>{
    try{
      let url = `${process.env.REACT_APP_BASE_API_URL}/management/jobs/deleteJob`;
      let config = {
        headers: {
          authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      let params = {
        job_id: this.state.selectedJob._id,
        reason: reason
      };
      console.log(params);
      let job = await axios.post(url,params,config);
      if(job.status == 200){
        this.setState({showDetail:false});
        this.getAllJobs();
      }
    }catch(err){
      console.log(err);
    }
  }
  render() {
    return this.context.isLoading ? (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={this.context.isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    ) : this.state.isFetching ? (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={this.state.isFetching}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    ) : (
      <div className="contentContainer">
        <Header
          header={"Jobs"}
          user={this.context.user}
          buttonTitle={"Generate Reference Code"}
        />
        <div className="clientDetailContainer">
          <div className="tabs">
            <div
              className={`tab ${
                this.state.activeTab == "open" ? "-activeTab" : ""
              }`}
              onClick={() => {
                this.changeData("open");
              }}
            >
              Open Jobs
            </div>
            <div
              className={`tab ${
                this.state.activeTab == "upcoming" ? "-activeTab" : ""
              }`}
              onClick={() => {
                this.changeData("upcoming");
              }}
            >
              Upcoming Jobs
            </div>
            <div
              className={`tab ${
                this.state.activeTab == "past" ? "-activeTab" : ""
              }`}
              onClick={() => {
                this.changeData("past");
              }}
            >
              Past Jobs
            </div>
            <div
              className={`tab ${
                this.state.activeTab == "cancelled" ? "-activeTab" : ""
              }`}
              onClick={() => {
                this.changeData("cancelled");
              }}
            >
              Cancelled Jobs
            </div>
          </div>
          <TableContainer className="notificationTableContainer">
            <Table>
              <TableHead className="header">
                <TableRow>
                  <TableCell className="cell">Client</TableCell>
                  <TableCell className="cell">Service</TableCell>
                  <TableCell className="cell">Job Description</TableCell>
                  <TableCell className="cell">Applicants</TableCell>
                  <TableCell className="cell">Job Date</TableCell>
                  <TableCell className="cell">Created At</TableCell>
                  <TableCell className="cell"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="content">
                {this.state.currentJobs.map((j, i) => (
                  <TableRow
                    key={i}
                    onClick={() => {
                      this.showJobDetail(j._id);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <TableCell className="cell" style={{ width: 200 }}>
                      {j.client_name}
                    </TableCell>
                    <TableCell className="cell" style={{ width: 150 }}>
                      {j.job_type}
                      <br />
                      {j.is_repeating ? "Repeat Job" : null}
                    </TableCell>
                    <TableCell className="cell" style={{ width: 250,  }}>
                      <span className="ellipsis">
                        {j.job_description}
                      </span>
                    </TableCell>
                    <TableCell className="cell" style={{ width: 125 }}>
                      {j.total_applicants}
                    </TableCell>
                    <TableCell className="cell">
                      {moment(j.job_start_date).format("ddd DD MMM HH:mm")}
                      <br />
                      {moment(j.job_end_date).format("ddd DD MMM HH:mm")}
                    </TableCell>
                    <TableCell className="cell">
                      {moment(j.created_at).format("ddd DD MMM HH:mm")}
                    </TableCell>
                    <TableCell className="cell" style={{ width: 75 }}>
                      <Button
                        className="more"
                        onClick={() => {
                          this.showJobDetail(j._id);
                        }}
                      >
                        <ArrowForwardIosOutlined />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <ReactPaginate
          breakLabel="..."
          nextLabel=">"
          onPageChange={this.handlePageClick}
          pageRangeDisplayed={5}
          pageCount={Math.ceil(this.state.activeList.length / 10)}
          previousLabel="<"
          renderOnZeroPageCount={null}
          activeClassName="activePagination"
          activeLinkClassName="activePagination"
          containerClassName="paginationContainer"
          pageClassName="paginationItem"
          pageLinkClassName="paginationItem"
          nextClassName="nextButton"
          nextLinkClassName="nextButton"
          previousClassName="nextButton"
          previousLinkClassName="nextButton"
          breakClassName="paginationItem"
          forcePage={this.state.page}
        />
        <Drawer
          open={this.state.showDetail}
          onClose={() => {
            this.setState({ showDetail: false });
          }}
          anchor="right"
        >
          <JobDetail
            job={this.state.selectedJob}
            close={() => {
              this.setState({ showDetail: false });
            }}
            deleteJob = {this.deleteJob}
          />
        </Drawer>
      </div>
    );
  }
}
