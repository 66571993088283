import React, { Component } from 'react'
import Header from './common/Header'
import UserContext from '../helper/UserContext'
import CreateAdmin from './common/CreateNewAdmin'
import axios from 'axios'

export default class Settings extends Component {
  static contextType = UserContext
  constructor(props) {
    super(props)
    this.state = {
      newAdminModal: false,
      isSuccess: false,
      oldPassword:'',
      newPassword:'',
      confirmPassword:'',
      showMessage:false,
      message:'',
      hasError:false
    }
  }
  openAdminModal = () => {
    this.setState({
      newAdminModal: !this.state.newAdminModal
    })
  }
  createAdmin = async (name, email) => {
    try {
      let url = `${process.env.REACT_APP_BASE_API_URL}/management/admin/newAdmin`
      let params = {
        name,
        email
      }
      let config = {
        headers: {
          authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }
      const addAdmin = await axios.post(url, params, config)
      if (addAdmin.status == 200) {
        this.setState({ isSuccess: true, hasError:false })
      }
    } catch (err) {
      this.setState({
        isSuccess:false,
        message:'The email address is already in use.',
        hasError:true
      })
      console.log(err)
    }
  }
  handleText = (e)=>{
    this.setState({
      [e.target.name]:e.target.value,
      showMessage:false,
      message:''
    })
  }

  changePassword = async () =>{
    console.log('old pass: ' + this.state.oldPassword + 'new pass: ' + this.state.newPassword + 'confirm: ' + this.state.confirmPassword)
    if(this.state.oldPassword == '' || this.state.newPassword == '' || this.state.confirmPassword == ''){
      return null
    }
    if(this.state.newPassword != this.state.confirmPassword){
      return null
    }
    try {
      let url = `${process.env.REACT_APP_BASE_API_URL}/users/changePassword`
      let params = {
        old_password:this.state.oldPassword,
        new_password:this.state.newPassword
      }
      let config = {
        headers: {
          authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }
      const updatePassword = await axios.post(url, params, config)
      console.log(updatePassword)
      if (updatePassword.status == 200) {
        this.setState({ showMessage: true, message:'Password changed.' })
        setTimeout(()=>{
          this.setState({showMessage:false,message:'', oldPassword:'', newPassword:'', confirmPassword:''})
        },1500)
      }
    } catch (err) {
      this.setState({
        showMessage:true,
        message:err.response.data.message
      })
      console.log(err)
    }
  }
  render() {
    return (
      <div className="contentContainer">
        <Header header={'Settings'} user={this.context.user} action={this.openAdminModal} buttonTitle={'Admin New Admin'} />
        <div className='clientDetailContainer'>
          <div className='infoContainer heightFix'>
            <span className='title'>Update Password</span>
            <input type="password" name="oldPassword" id='oldPassword' placeholder="Current Password" onChange={(e)=>{this.handleText(e)}} value={this.state.oldPassword}/>
            <input type="password" name="newPassword" id='newPassword' placeholder="New Password" onChange={(e)=>{this.handleText(e)}} value={this.state.newPassword}/>
            <input type="password" name="confirmPassword" id='confirmPassword' placeholder="Confirm Password" onChange={(e)=>{this.handleText(e)}} value={this.state.confirmPassword}/>
            {this.state.showMessage && <span className='name'>{this.state.message}</span> }
            <button type="submit" name="login" id="login" className="saveButton" onClick={()=>{this.changePassword()}} style={{marginBottom:25, marginTop:15}}>Save</button>
          </div>
        </div>
        <CreateAdmin open={this.state.newAdminModal} close={this.openAdminModal} createAdmin={this.createAdmin} isSuccess={this.state.isSuccess} message={this.state.message} hasError={this.state.hasError} />
      </div >
    )
  }
}
