import React,{useContext, useEffect} from 'react'
import {BrowserRouter} from 'react-router-dom'
import Routes from './Router'
import Navigation from './components/Navigation'
import {UserProvider} from './helper/UserContext'
export default function App() {

    
    return (
        <div className="mainHolder">
            <UserProvider>
                <BrowserRouter>
                    <Navigation/>
                    <Routes />
                </BrowserRouter>
            </UserProvider>
        </div>
    )
}
