import React, { Component } from 'react'
import { Calendar, momentLocalizer, Views } from 'react-big-calendar'
import moment from 'moment'
import events from './events'
import Header from './common/Header'
import UserContext from '../helper/UserContext'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Backdrop, CircularProgress} from '@mui/material/';
import axios from 'axios'

const localizer = momentLocalizer(moment)

export default class Availability extends Component {
    static contextType = UserContext
    constructor(props){
        super(props)
        this.state ={
            events:[],
            start_date:new Date(),
            showEventDetail:false,
            isFetching:true
        }
    }
    async componentDidMount(){
        if(await this.fetchMyProfile()){
            let events = []
            try{
                let url=`${process.env.REACT_APP_BASE_API_URL}/nannies/getMyAvailabilities`
                let config = {
                    headers:{
                        authorization:'Bearer ' + localStorage.getItem("token")
                    }
                }
                const myAvailabilities = await axios.get(url,config)
                console.log(myAvailabilities)
                myAvailabilities.data.availabilities.forEach(_e=>{
                    let _event = {
                        title:_e.title,
                        start:new Date(_e.start),
                        end:new Date(_e.end),
                        id:_e._id,
                        booking_id:_e.booking_id
                    }
                    events.push(_event)
                })
                this.setState({
                    events:events,
                    isFetching:false
                })
            }catch(err){
                console.log(err)
            }
        }

    }
    fetchMyProfile = async () =>{
        this.setState({isFetching:true})
        let url =`${process.env.REACT_APP_BASE_API_URL}/nannies/me`
        let config = {
            headers:{
                authorization:'Bearer ' + localStorage.getItem('token')
            }
        }
        const me = await axios.get(url,config)
        if(!me.data.me.approved){
            this.setState({
                profileNotApproved:true,
                isFetching:false
            })
            return false
        } else {
            return true
        }
        
    }
    selectSlot = (start,end) =>{
        let _event ={
            start:new Date(start.start),
            end:new Date(start.end)
        }
        this.setState({
            showEventDetail:true,
            title:'Are you sure?',
            message:`You're about to set your calendar as not available between`,
            time_range_start:`${moment(start.start).format('llll')}`,
            time_range_end:`${moment(start.end).format('llll')}`,
            button_text:'Confirm',
            alert_type:'add_new',
            start:new Date(start.start),
            end:new Date(start.end)
        })

    }
    showMore = (event) =>{
        if(event.title == 'Booking'){
            this.props.history.push(`/bookingDetails/${event.booking_id}`)
        }
        this.setState({
            showEventDetail:true,
            title:'Are you sure?',
            message:`You're about to change your availability status between`,
            time_range_start:`${moment(event.start).format('llll')}`,
            time_range_end:`${moment(event.end).format('llll')}`,
            button_text:'Remove',
            alert_type:'remove',
            event_id:event.id
        })
    }
    confirmEvent = async (alert_type, _id) =>{
        try{
            if(alert_type == 'add_new'){
                console.log('this is a new availability set event')
                let _event = {
                    title:'Not available',
                    start:this.state.start,
                    end:this.state.end
                }
                let url=`${process.env.REACT_APP_BASE_API_URL}/nannies/setAvailability`
                let config = {
                    headers:{
                        authorization:'Bearer ' + localStorage.getItem("token")
                    }
                }
                let params ={
                    title:_event.title,
                    start:_event.start,
                    end:_event.end
                }
                console.log(params)
                const setAvailability = await axios.post(url,params,config)
                console.log(setAvailability)
                if(setAvailability.status == 200){
                    this.setState({
                        showEventDetail:false,
                        events:[...this.state.events, _event]
                    })
                }
            } else {
                let url=`${process.env.REACT_APP_BASE_API_URL}/nannies/removeAvailability`
                let config = {
                    headers:{
                        authorization:'Bearer ' + localStorage.getItem("token")
                    }
                }
                let params ={
                    _id:this.state.event_id
                }
                const removeEvent = await axios.post(url,params,config)
                if(removeEvent.status == 200){
                    let _e = this.state.events.filter(event=>event.id != this.state.event_id)
                    this.setState({
                        events:_e
                    })
                }
                this.setState({showEventDetail:false})
            }
        }catch(err){
            console.log(err)
        }
    }
  render() {

    return (
        this.state.isFetching ? 
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={this.state.isFetching}
            >
                <CircularProgress color="inherit" />
            </Backdrop> : 
            <div className="contentContainer">
                <Header header={'Availability'} user={this.context.user} openModal={this.openReferenceModal} buttonTitle={'Generate Reference Code'}/>
                {this.state.profileNotApproved ?
                    <div>
                        <p>After your profile approved, you can manage your availabilities on this page.</p>
                    </div>
                :
                <div style={{height:900, width:'100%'}}>
                    <Calendar
                        localizer={localizer}
                        defaultView={Views.WEEK}
                        views={{ month: false, week: true, day: false, agenda: false, }}
                        events={this.state.events}
                        startAccessor="start"
                        endAccessor="end"
                        style={{ height: 900 }}
                        selectable
                        onSelectSlot={this.selectSlot}
                        onDoubleClickEvent={(event)=>{this.showMore(event)}}
                        min={moment('8:00am', 'hh:mma').toDate()}
                        max={moment('11:59pm', 'hh:mma').toDate()}
                    />
                </div> }
                <Dialog open={this.state.showEventDetail} onClose={()=>{this.setState({showEventDetail:false})}} maxWidth='md' fullWidth={true} >
                    <DialogTitle>{this.state.title}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {this.state.message}<br/>
                            Start: {this.state.time_range_start}<br/>
                            End: {this.state.time_range_end}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <button type="submit" onClick={()=>{this.setState({showEventDetail:false})}} className="buttonMain">
                            <span>Cancel</span>
                        </button>
                        <button type="submit" onClick={()=>{this.confirmEvent(this.state.alert_type)}} className="buttonMain">
                            <span>{this.state.button_text}</span>
                        </button>
                    </DialogActions>
                </Dialog> 
        </div>
    )
  }
}
