import React, { Component } from 'react'
import UserContext from '../helper/UserContext'
import Header from './common/Header'
import { Backdrop, CircularProgress, Select, MenuItem, FormControl, InputLabel, TextField, Button } from '@mui/material/';
import GenerateReference from './common/GenerateReference'
import CreateNanny from './common/CreateNewNanny';
import axios from 'axios'
import { Link } from 'react-router-dom'
import moment from 'moment';
import ReactPaginate from 'react-paginate';

export default class Nannies extends Component {
    static contextType = UserContext
    constructor(props) {
        super(props)
        this.state = {
            nannies: [],
            activeList:[],
            currentList:[],
            approvedNannies:[],
            rejectedNannies:[],
            awaitingNannies:[],
            isLoading: false,
            newNannyModal: false,
            isSuccess: false,
            isFetching: false,
            selectedFilter:'Awaiting',
            hasError:false,
            message:'',
            page:0,
            searchQuery:''
        }
    }

    async componentDidMount() {
        this.getAllNannies()
        this.setState({ isLoading: this.context.isLoading })
    }

    getAllNannies = async () => {
        this.setState({ isFetching: true })
        try {
            let url = `${process.env.REACT_APP_BASE_API_URL}/management/nannies/getAllNannies`
            let config = {
                headers: {
                    authorization: 'Bearer ' + localStorage.getItem('token')
                }
            }
            const nannies = await axios.get(url, config)
            console.log(nannies)
            this.setState({
                nannies: nannies.data.nannies,
                approvedNannies:nannies.data.nannies.filter(n=>n.statusText == "Approved"),
                rejectedNannies:nannies.data.nannies.filter(n=>n.statusText == "Rejected"),
                awaitingNannies:nannies.data.nannies.filter(n=>n.statusText == "Awaiting Approval"),
                activeList:nannies.data.nannies.filter(n=>n.statusText == "Awaiting Approval"),
                currentList:nannies.data.nannies.filter(n=>n.statusText == "Awaiting Approval").slice(0,10),
                isFetching: false
            })
        } catch (err) {
            console.log('err')
        }
    }

    openReferenceModal = () => {
        this.setState({
            referenceModal: !this.state.referenceModal
        })
    }
    generate = async (client_email) => {
        let url = `${process.env.REACT_APP_BASE_API_URL}/management/clients/generateReferenceCode`
        let params = {
            client_email
        }
        let config = {
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('token')
            }
        }
        const generateCode = await axios.post(url, params, config)
        if (generateCode.status == 200) {
            this.setState({ isSuccess: true })
        }
    }
    openModal = () => {
        this.setState({
            newNannyModal: !this.state.newNannyModal,
            isSuccess:false,
            hasError:false,
            message:''
        })
    }
    createNanny = async (name, email) => {
        try {
            let url = `${process.env.REACT_APP_BASE_API_URL}/management/nannies/addNewNanny`
            let params = {
                name,
                email
            }
            let config = {
                headers: {
                    authorization: 'Bearer ' + localStorage.getItem('token')
                }
            }
            const addNanny = await axios.post(url, params, config)
            if (addNanny.status == 200) {
                this.setState({ isSuccess: true })
            }
        } catch (err) {
            this.setState({
                message:err.response.data.message,
                hasError:true
            })
            console.log(err)
        }
    }
    

    handleFilter = (v) =>{
        this.setState({
            selectedFilter:v,
            activeList: v == 'Approved' ? this.state.approvedNannies : v == 'Rejected' ? this.state.rejectedNannies : this.state.awaitingNannies,
            currentList: v == 'Approved' ? this.state.approvedNannies.slice(0,10) : v == 'Rejected' ? this.state.rejectedNannies.slice(0,10) : this.state.awaitingNannies.slice(0,10),
            page:0
        })
    }

    handlePageClick = (page)=>{
        this.setState({
            currentList:this.state.activeList.slice((page.selected * 10), (((page.selected + 1) * 10) )),
            page:page.selected
        })
    }
    search = async () =>{
        if(this.state.searchQuery == ''){return null}
        try{
            let url = `${process.env.REACT_APP_BASE_API_URL}/management/nannies/searchByName`
            let config = {
                headers:{
                    authorization:'Bearer ' + localStorage.getItem('token')
                }
            }
            let params = {
                name:this.state.searchQuery
            }
            let nannies = await axios.post(url,params,config)
            if(nannies.status == 200){
                if(nannies.data.nannies.length > 0){
                    this.setState({currentList:nannies.data.nannies.slice(0,10), page:0, activeList:nannies.data.nannies})
                }
            }
        }catch(err){
            console.log(err)
        }
    }
    clearSearch = async () =>{
        this.setState({searchQuery:''})
        await this.getAllNannies()
    }

    render() {
        console.log(this.state.activeList)
        return (
            this.context.isLoading ?
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={this.context.isLoading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                :
                this.state.isFetching ?
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={this.state.isFetching}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    :
                    <div className="contentContainer">
                        <Header header={'Teachers'} user={this.context.user} action={this.openModal} buttonTitle={'Invite New Teacher'} />
                        <div className='searchContainer'>
                            <TextField onChange={(e)=>{this.setState({searchQuery:e.target.value})}} value={this.state.searchQuery} style={{flex:1, marginRight:25}} placeholder='Teacher Name'/>
                            <Button className='searchButton' onClick={()=>{this.search()}}>
                                <span>Search</span>
                            </Button>
                            <Button onClick={()=>{this.clearSearch()}}>
                                <span>Clear</span>
                            </Button>
                        </div>
                        <div className="table booking">
                            <div className="tableHeader">
                                <span style={{width:150}}></span>
                                <span style={{width:450}}>Name</span>
                                <span style={{width:250}}>Reference Code</span>
                                <span>
                                    <FormControl fullWidth variant="standard" >

                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={this.state.selectedFilter}

                                            onChange={(e)=>{this.handleFilter(e.target.value)}}
                                        >
                                            <MenuItem value='Awaiting'>Awaiting Approval</MenuItem>
                                            <MenuItem value='Approved'>Approved</MenuItem>
                                            <MenuItem value='Rejected'>Rejected</MenuItem>
                                        </Select>
                                    </FormControl>
                                </span>
                            </div>
                            {this.state.currentList.map(nanny =>
                                <Link
                                    to={{
                                        pathname: `/teacherDetails/${nanny.nanny_id}`,
                                    }}
                                    style={{ textDecoration: 'none' }}
                                >
                                    <div className="tableContentItem nanniesItem">
                                    <span style={{width:150}}>{nanny.photo_url != null ? <img src={nanny.photo_url} /> : <img src={require('../assets/profile.png')}/>}</span>
                                        <span className="nameContainer">{nanny.name}<br/><span className='email'>{nanny.email}</span></span>
                                        <span className="nameContainer codeFix">{this.state.selectedFilter == 'Awaiting' ? nanny.referenceCode : '-'}<br/></span>
                                        <span style={{ textTransform: 'capitalize' }}>{nanny.statusText}</span>
                                    </div>
                                </Link>
                            )}
                            <ReactPaginate
                                breakLabel="..."
                                nextLabel=">"
                                onPageChange={this.handlePageClick}
                                pageRangeDisplayed={5}
                                pageCount={Math.ceil(this.state.activeList.length / 10)}
                                previousLabel="<"
                                renderOnZeroPageCount={null}
                                activeClassName="activePagination"
                                activeLinkClassName="activePagination"
                                containerClassName='paginationContainer'
                                pageClassName='paginationItem'
                                pageLinkClassName='paginationItem'
                                nextClassName='nextButton'
                                nextLinkClassName='nextButton'
                                previousClassName='nextButton'
                                previousLinkClassName='nextButton'
                                breakClassName='paginationItem'
                                forcePage={this.state.page}
                                
                            />
                        </div>
                        <CreateNanny open={this.state.newNannyModal} createNanny={this.createNanny} close={this.openModal} isSuccess={this.state.isSuccess} hasError={this.state.hasError} message={this.state.message}/>
                    </div>
        )
    }
}
