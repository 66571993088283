import React, { Component } from "react";
import {
    Backdrop,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Drawer,
} from "@mui/material";
import Header from "./common/Header";
import UserContext from "../helper/UserContext";
import axios from "axios";
import moment from "moment";
import ReactPaginate from "react-paginate";
import ActivityDetail from "./ActivityDetail";

export default class Activities extends Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "awaiting",
      activities: [],
      activeActivities: [],
      cancelledActivities: [],
      awaitingActivities: [],
      currentList: [],
      activeList: [],
      showDetail: false,
      selectedActivity:''
    };
  }
  componentDidMount = async () => {
    let activities = await this.getActivities();
    if(activities != null){
      this.filterActivities(activities)
    }
  };
  changeData = (tab) => {
    this.setState({
      activeTab: tab,
      selectedFilter: tab,
      activeList:
        tab == "approved"
          ? this.state.activeActivities
          : tab == "cancel"
          ? this.state.cancelledActivities
          : this.state.awaitingActivities,
      currentList:
        tab == "approved"
          ? this.state.activeActivities.slice(0, 10)
          : tab == "cancel"
          ? this.state.cancelledActivities.slice(0, 10)
          : this.state.awaitingActivities.slice(0, 10),
      page: 0,
    });
  };
  getActivities = async () => {
    try {
      let url = `${process.env.REACT_APP_BASE_API_URL}/management/activities/getAllActivities`;
      let config = {
        headers: {
          authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      let activities = await axios.get(url, config);
      if (activities.status == 200) {
        if (activities.data.activities.length > 0) {
          let a = activities.data.activities;
          return a
        }
      } else {
        return null
      }
      console.log(activities);
    } catch (err) {}
  };
  filterActivities = async (a)=>{
    console.log(a)
    this.setState({
      activities: a,
      activeActivities: a.filter((a) => a.is_approved == true && a.is_cancelled == false && a.is_ended == false),
      awaitingActivities: a.filter(
        (a) =>
          a.is_approved == false &&
          a.is_cancelled == false &&
          a.is_ended == false
      ),
      currentList: a.filter(
        (a) =>
          a.is_approved == false &&
          a.is_cancelled == false &&
          a.is_ended == false
      ),
      cancelledActivities: a.filter((a) => a.is_cancelled == true),
    });
  }
  handlePageClick = (page) => {
    this.setState({
      currentList: this.state.activeList.slice(
        page.selected * 10,
        (page.selected + 1) * 10
      ),
      page: page.selected,
    });
  };
  makeActivityDateReady = (freq, customdays, date) => {
    let activityDate = "";
    let nextActivityDate = "";
    let ordered_days = [];
    for (let i = 0; i < customdays.length; i++) {
      let _d = {
        index: parseInt(moment().isoWeekday(customdays[i]).format("E")),
        day: customdays[i],
      };
      ordered_days.push(_d);
    }
    ordered_days.sort((a, b) => a.index - b.index);

    if (freq == "everyday") {
      activityDate = "Everyday";
      nextActivityDate = moment(date).add(1, "days").format("DD MMM");
    } else if (freq == "everyweek") {
      activityDate = "Every " + moment(date).format("dddd");
      nextActivityDate = moment(date).add(1, "weeks").format("DD MMM");
    } else if (freq == "custom") {
      let cd = "";
      for (let i = 0; i < ordered_days.length; i++) {
        cd = cd + ordered_days[i].day.slice(0, 3) + "/";
      }
      cd = cd.slice(0, cd.length - 1);
      activityDate = "Every " + cd;
      let failed = false;

      console.log(ordered_days);
      for (let i = 0; i < customdays.length; i++) {
        let today = moment();
        let t = parseInt(moment().isoWeekday(today.format("dddd")).format("E"));
        let n = parseInt(moment().isoWeekday(customdays[i]).format("E"));
        if (n > t) {
          let d = n - t;
          nextActivityDate = today.add(d, "days").format("DD MMM");
          break;
        }
        failed = true;
      }
      if (failed) {
        let today = moment();
        let t = parseInt(moment().isoWeekday(today.format("dddd")).format("E"));
        let n = parseInt(moment().isoWeekday(ordered_days[0].day).format("E"));
        let b = 7 - (t - n);
        nextActivityDate = today.add(b, "days").format("DD MMM");
        console.log(nextActivityDate);
      }
    }
    return { activityDate, nextActivityDate };
  };
  refreshList = async () =>{
    let list = this.state.currentList
    list = list.filter(l=>l.activity_id != this.state.selectedActivity)
    this.setState({currentList:list})
    let activities = await this.getActivities()
    await this.filterActivities(activities)
    
  }
  render() {
    return (
      <div className="contentContainer">
        <Header
          header={"Activities"}
          user={this.context.user}
          showClientList={false}
          hideAction={true}
        />
        <div className="clientDetailContainer">
          <div className="tabs">
            <div
              className={`tab ${
                this.state.activeTab == "awaiting" ? "-activeTab" : ""
              }`}
              onClick={() => {
                this.changeData("awaiting");
              }}
            >
              Awaiting Confirmation
            </div>
            <div
              className={`tab ${
                this.state.activeTab == "approved" ? "-activeTab" : ""
              }`}
              onClick={() => {
                this.changeData("approved");
              }}
            >
              Approved
            </div>
            <div
              className={`tab ${
                this.state.activeTab == "cancel" ? "-activeTab" : ""
              }`}
              onClick={() => {
                this.changeData("cancel");
              }}
            >
              Cancelled
            </div>
          </div>
          <TableContainer className="notificationTableContainer">
            <Table>
              <TableHead className="header">
                <TableRow>
                  <TableCell className="cell">Teacher Name</TableCell>
                  <TableCell className="cell">Activity Name</TableCell>
                  <TableCell className="cell">Activity Date</TableCell>
                  <TableCell className="cell">Created At</TableCell>
                  <TableCell className="cell">Activity Type</TableCell>
                  <TableCell className="cell">Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="content">
                {this.state.activities.length > 0
                  ? this.state.currentList.map((a, i) => (
                      <TableRow
                        key={i}
                        onClick={() => {
                          this.setState({selectedActivity:a.activity_id, showDetail:true});
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <TableCell className="cell">
                          {a.teachers[0].name}
                        </TableCell>
                        <TableCell className="cell">{a.lesson_name}</TableCell>
                        {a.is_repeating ? (
                          <TableCell className="cell">
                            {
                              moment(a.lesson_date).format("DD MMM")
                            }{" "}
                            at {moment(a.lesson_date).format("HH:mm")}
                            <br />
                            Next Activity:{" "}
                            {
                              this.makeActivityDateReady(
                                a.repeat_frequency,
                                a.custom_days,
                                a.lesson_date
                              ).nextActivityDate
                            }
                          </TableCell>
                        ) : (
                          <TableCell className="cell">{`${moment(
                            a.lesson_date
                          ).format("ddd DD MMM")} at ${moment(
                            a.lesson_date
                          ).format("HH:mm")}`}</TableCell>
                        )}
                        <TableCell className="cell">{moment(a.created_at).format("DD MMMM HH:mm")}</TableCell>
                        <TableCell className="cell">{a.lesson_type}</TableCell>
                        <TableCell className="cell">
                          {a.is_approved && !a.is_cancelled
                            ? "Approved"
                            : a.is_cancelled
                            ? "Cancelled"
                            : "Awaiting Confirmation"}
                        </TableCell>
                      </TableRow>
                    ))
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <ReactPaginate
          breakLabel="..."
          nextLabel=">"
          onPageChange={this.handlePageClick}
          pageRangeDisplayed={5}
          pageCount={Math.ceil(this.state.activeList.length / 10)}
          previousLabel="<"
          renderOnZeroPageCount={null}
          activeClassName="activePagination"
          activeLinkClassName="activePagination"
          containerClassName="paginationContainer"
          pageClassName="paginationItem"
          pageLinkClassName="paginationItem"
          nextClassName="nextButton"
          nextLinkClassName="nextButton"
          previousClassName="nextButton"
          previousLinkClassName="nextButton"
          breakClassName="paginationItem"
          forcePage={this.state.page}
        />
        <Drawer
          open={this.state.showDetail}
          onClose={() => {
            this.setState({ showDetail: false });
          }}
          anchor="right"
        >
          <ActivityDetail activity_id={this.state.selectedActivity} refresh={this.refreshList} />
        </Drawer>
      </div>
    );
  }
}
