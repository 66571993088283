import React, { Component } from 'react'
import axios from 'axios'

export default class ForgetPassword extends Component {
    constructor(props){
        super(props)
        this.state ={
            verification_code:'',
            password:'',
            password_confirm:'',
            email:'',
            requested:false
        }
    }
    handleInput=(e)=>{
        this.setState({
            [e.target.name]:e.target.value
        })
    }
    requestCode = async () =>{
        if(this.state.email == ''){
            return null
        }
        try{
            let url = `${process.env.REACT_APP_BASE_API_URL}/forgetPassword`
            let params = {
                email:this.state.email
            }
            const newCode = await axios.post(url,params)
            if(newCode.status == 200){
                this.setState({
                    requested:true
                })
            }
        }catch(err){
            console.log(err)
        }
    }
    updatePassword = async () =>{
        if(this.state.verification_code == '' || this.state.password == '' || this.state.password_confirm == '' ||(this.state.password != this.state.password_confirm)){
            return null
        }
        try{
            let url = `${process.env.REACT_APP_BASE_API_URL}/updatePassword`
            let params ={
                verification_code:this.state.verification_code,
                password:this.state.password
            }
            const update_password = await axios.post(url,params)
            if(update_password.status == 200){
                console.log('password changed!')
                this.props.history.push('/Login')
            } else {
                console.log('something went wrong')
            }
        }catch(err){
            console.log(err)
        }
    }
  render() {
    return (
        <div className="login">
        <h1>The Rocket House</h1>
        {!this.state.requested ? 
            <div className="loginContainer">
                <input type="text" name="email" id='email' placeholder="email" onChange={(e)=>{this.handleInput(e)}} className="loginInput"/>
                <button type="submit" name="login" id="login" className="loginButton" onClick={()=>{this.requestCode()}}>Request verification code</button>
            </div>
            :
            <div className="loginContainer">
                <span className='verificationCodeDesc'>We've sent your verification code to your registered email address. The code is valid for 15 minutes. </span>
                <input type="text" name="verification_code" id='verification_code' placeholder="Verification code" onChange={(e)=>{this.handleInput(e)}} className="loginInput"/>
                <input type="password" name="password" id='password' placeholder="Type your new password" onChange={(e)=>{this.handleInput(e)}} className="loginInput"/>
                <input type="password" name="password_confirm" id='password_confirm' placeholder="Confirm your password" onChange={(e)=>{this.handleInput(e)}} className="loginInput"/>
                <button type="submit" name="login" id="login" className="loginButton" onClick={()=>{this.updatePassword()}}>Update Password</button>
            </div>
        }
    </div>
    )
  }
}
