import React, { Component } from "react";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import UserContext from "../helper/UserContext";
import axios from "axios";
import moment from "moment";
import Message from "./common/Message";

export default class ActivityDetail extends Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      activity: {},
      teacher: {},
      participants: [],
      showInfo: false,
      teachers: [],
    };
  }
  async componentDidMount() {
    console.log(this.props.activity_id);
    await this.getActivity(this.props.activity_id);
  }
  getActivity = async (_id) => {
    try {
      let url = `${process.env.REACT_APP_BASE_API_URL}/management/activities/getActivity?activity_id=${_id}`;
      let config = {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      let activity = await axios.get(url, config);
      if (activity.status == 200) {
        this.setState({
          activity: activity.data.activity,
          teacher: activity.data.activity.teacher,
          participants: activity.data.activity.participants,
          teachers: activity.data.activity.teachers,
        });
      }
      console.log(activity);
    } catch (err) {
      console.log(err);
    }
  };
  makeActivityDateReady = (freq, customdays, date) => {
    let activityDate = "";
    let nextActivityDate = "";
    let ordered_days = [];
    for (let i = 0; i < customdays.length; i++) {
      let _d = {
        index: parseInt(moment().isoWeekday(customdays[i]).format("E")),
        day: customdays[i],
      };
      ordered_days.push(_d);
    }
    ordered_days.sort((a, b) => a.index - b.index);

    if (freq == "everyday") {
      activityDate = "Everyday";
      nextActivityDate = moment(date).add(1, "days").format("DD MMM");
    } else if (freq == "everyweek") {
      activityDate = "Every " + moment(date).format("dddd");
      nextActivityDate = moment(date).add(1, "weeks").format("DD MMM");
    } else if (freq == "custom") {
      let cd = "";
      for (let i = 0; i < ordered_days.length; i++) {
        cd = cd + ordered_days[i].day.slice(0, 3) + "/";
      }
      cd = cd.slice(0, cd.length - 1);
      activityDate = "Every " + cd;
      let failed = false;

      console.log(ordered_days);
      for (let i = 0; i < customdays.length; i++) {
        let today = moment();
        let t = parseInt(moment().isoWeekday(today.format("dddd")).format("E"));
        let n = parseInt(moment().isoWeekday(customdays[i]).format("E"));
        if (n > t) {
          let d = n - t;
          nextActivityDate = today.add(d, "days").format("DD MMM");
          break;
        }
        failed = true;
      }
      if (failed) {
        let today = moment();
        let t = parseInt(moment().isoWeekday(today.format("dddd")).format("E"));
        let n = parseInt(moment().isoWeekday(ordered_days[0].day).format("E"));
        let b = 7 - (t - n);
        nextActivityDate = today.add(b, "days").format("DD MMM");
        console.log(nextActivityDate);
      }
    }
    return { activityDate, nextActivityDate };
  };
  approve = async () => {
    try {
      let url = `${process.env.REACT_APP_BASE_API_URL}/management/activities/approveActivity`;
      let config = {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      let params = {
        activity_id: this.state.activity.activity_id,
      };
      let approved = await axios.post(url, params, config);
      if (approved.status == 200) {
        this.setState({
          message: "The activity has been approved!",
          showInfo: true,
        });
        this.props.refresh();
      }
    } catch (err) {
      this.setState({
        message: "Something went wrong! Please try again later.",
        showInfo: true,
      });
      console.log(err);
    }
  };
  cancel = async () => {
    try {
      let url = `${process.env.REACT_APP_BASE_API_URL}/management/activities/cancelActivity`;
      let config = {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      let params = {
        activity_id: this.state.activity.activity_id,
      };
      let approved = await axios.post(url, params, config);
      if (approved.status == 200) {
        this.setState({
          message: "The activity has been cancelled!",
          showInfo: true,
        });
        this.props.refresh();
      }
    } catch (err) {
      this.setState({
        message: "Something went wrong! Please try again later.",
        showInfo: true,
      });
      console.log(err);
    }
  };
  closeMessage = () => {
    this.setState({
      message: "",
      showInfo: false,
    });
  };
  render() {
    return (
      <div className="activityDetail">
        <Button className="closeButton" onClick={this.props.close}>
          X
        </Button>
        <div style={{ width: "95%" }}>
          <h3>
            {this.state.activity.lesson_name}
            {/* <Link
            to={{
              pathname: `/teacherDetails/${this.state.activity.teacher_id}`,
            }}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            {this.state.teacher.name}
          </Link> */}
          </h3>
          <img
            src={this.state.activity.photo_url}
            style={{ width: "100%", height: "auto", borderRadius: 20 }}
          />
          <p>{this.state.activity.description}</p>
          <h4>Teacher(s)</h4>
          <div>
            {this.state.teachers != null ? (
              this.state.teachers.map((t) => (
                <Link
                  to={{
                    pathname: `/teacherDetails/${t.nannyID}`,
                  }}
                  key={t.nannyID}
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <div className="participantCard" style={{ marginTop: 5 }}>
                    <img
                      src={t.profile_image}
                      style={{
                        width: 64,
                        height: 64,
                        borderRadius: 32,
                        marginRight: 20,
                      }}
                    />
                    <div style={{ flexDirection: "column", display: "flex" }}>
                      <h5>{t.name}</h5>
                    </div>
                  </div>
                </Link>
              ))
            ) : (
              <Link
                to={{
                  pathname: `/teacherDetails/${this.state.teacher.nannyID}`,
                }}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <div className="participantCard" style={{ marginTop: 5 }}>
                  <img
                    src={this.state.teacher.profile_image}
                    style={{
                      width: 64,
                      height: 64,
                      borderRadius: 32,
                      marginRight: 20,
                    }}
                  />
                  <div style={{ flexDirection: "column", display: "flex" }}>
                    <h5>{this.state.teacher.name}</h5>
                  </div>
                </div>
              </Link>
            )}
          </div>

          <div
            style={{ flexDirection: "column", display: "flex", marginTop: 15 }}
          >
            <span>
              <span className="bold">Activity Time: </span>{" "}
              {!this.state.activity.is_repeating
                ? `${moment(this.state.activity.lesson_date).format(
                    "ddd DD MMM"
                  )} at ${moment(this.state.activity.lesson_date).format(
                    "HH:mm"
                  )}`
                : null}
              {this.state.activity.is_repeating
                ? `${
                    this.makeActivityDateReady(
                      this.state.activity.repeat_frequency,
                      this.state.activity.custom_days,
                      this.state.activity.lesson_date
                    ).activityDate
                  } at ${moment(this.state.activity.lesson_date).format(
                    "HH:mm"
                  )}`
                : null}
              <br />
              {this.state.activity.is_repeating
                ? `Next Activity:
            ${
              this.makeActivityDateReady(
                this.state.activity.repeat_frequency,
                this.state.activity.custom_days,
                this.state.activity.lesson_date
              ).nextActivityDate
            }`
                : null}
            </span>
            <span>
              <span className="bold">Recommended Age:</span>{" "}
              {this.state.activity.age_range}
            </span>
            <span>
              <span className="bold">Activity Type:</span>{" "}
              {this.state.activity.lesson_type}
            </span>
            {this.state.activity.lesson_type == "In-Person" ? (
              <span>
                <span className="bold">Activity Location: </span>
                {this.state.activity.lesson_location}
              </span>
            ) : null}
            <span>
              <span className="bold">Group Size:</span>{" "}
              {this.state.activity.group_size}
            </span>
            <span>
              <span className="bold">Cost:</span> £{this.state.activity.cost}
            </span>
          </div>
          <h4>Participants</h4>
          <div>
            {this.state.participants.map((p) => (
              <Link
                to={{
                  pathname: `/clientDetails/${p.client_id}`,
                }}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <div className="participantCard">
                  <img
                    src={p.profile_image}
                    style={{
                      width: 64,
                      height: 64,
                      borderRadius: 32,
                      marginRight: 20,
                    }}
                  />
                  <div style={{ flexDirection: "column", display: "flex" }}>
                    <h5>{p.name}</h5>
                    <span>
                      {p.kids_name} / {p.kids_age} year-old
                    </span>
                    <span>{p.notes}</span>
                  </div>
                </div>
              </Link>
            ))}
          </div>
          {!this.state.activity.is_cancelled &&
          !this.state.activity.is_approved ? (
            <div
              style={{ display: "flex", flexDirection: "row", marginTop: 25 }}
            >
              <button
                type="submit"
                name="approve"
                id="approve"
                className="saveButton"
                onClick={() => {
                  this.approve();
                }}
              >
                Approve
              </button>
              <button
                type="submit"
                name="cancel"
                id="cancel"
                className="saveButton cancel"
                onClick={() => {
                  this.cancel();
                }}
              >
                Cancel
              </button>
            </div>
          ) : null}
        </div>
        <Message
          message={this.state.message}
          close={this.closeMessage}
          open={this.state.showInfo}
        />
      </div>
    );
  }
}
