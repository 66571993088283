import React, { Component } from 'react'
import UserContext from '../helper/UserContext'
import Header from './common/Header'
import { Backdrop, CircularProgress } from '@mui/material/';
import GenerateReference from './common/GenerateReference'
import axios from 'axios'
import { Link } from 'react-router-dom'

export default class Services extends Component {
    static contextType = UserContext
    constructor(props) {
        super(props)
        this.state = {
            nannies: [],
            services: [],
            isLoading: false,
            referenceModal: false,
            isSuccess: false,
            isFetching: false
        }
    }

    async componentDidMount() {
        this.getAllServices()
        //this.getAllNannies()
        this.setState({ isLoading: this.context.isLoading })
    }

    getAllServices = async () => {
        this.setState({
            isFetching: true
        })
        try {
            let url = `${process.env.REACT_APP_BASE_API_URL}/management/services/getAllServices`
            let config = {
                headers: {
                    authorization: 'Bearer ' + localStorage.getItem('token')
                }
            }
            const services = await axios.get(url, config)
            this.setState({
                services: services.data.AllServices,
                isFetching: false
            })
            console.log(services)
        } catch (err) {
            console.log(err)
        }
    }

    getAllNannies = async () => {
        this.setState({ isFetching: true })
        try {
            let url = `${process.env.REACT_APP_BASE_API_URL}/management/nannies/getAllNannies`
            let config = {
                headers: {
                    authorization: 'Bearer ' + localStorage.getItem('token')
                }
            }
            const nannies = await axios.get(url, config)
            console.log(nannies)
            this.setState({
                nannies: nannies.data.nannies,
                isFetching: false
            })
        } catch (err) {
            console.log('err')
        }
    }

    openReferenceModal = () => {
        this.setState({
            referenceModal: !this.state.referenceModal
        })
    }
    generate = async (client_email) => {
        let url = `${process.env.REACT_APP_BASE_API_URL}/management/clients/generateReferenceCode`
        let params = {
            client_email
        }
        let config = {
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('token')
            }
        }
        const generateCode = await axios.post(url, params, config)
        if (generateCode.status == 200) {
            this.setState({ isSuccess: true })
        }
    }
    newNanny = () => {
        this.props.history.push('/AddNewService')
    }
    render() {
        return (
            this.context.isLoading ?
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={this.context.isLoading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                :
                this.state.isFetching ?
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={this.state.isFetching}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    :
                    <div className="contentContainer">
                        <Header header={'Services'} user={this.context.user} action={this.newNanny} buttonTitle={'Add New Service'} />
                        <div className="table booking">
                            <div className="tableHeader" style={{ width: '98%' }}>
                                <span></span>
                                <span>Name</span>
                            </div>
                            {this.state.services.map(s =>

                                <Link
                                    to={{
                                        pathname: `/UpdateService/${s.service_id}`,
                                    }}
                                    style={{ textDecoration: 'none', marginBottom: 10 }}
                                >
                                    <div className="tableContentItem nanniesItem" style={{ width: '98%', }}>
                                        <span><img src={s.service_image_url} style={{ height: 'auto' }} /></span>
                                        <span style={{ textTransform: 'capitalize' }}>{s.service_name}</span>
                                    </div>
                                </Link>
                            )}
                        </div>
                        <GenerateReference open={this.state.referenceModal} generate={this.generate} close={this.openReferenceModal} isSuccess={this.state.isSuccess} />
                    </div>
        )
    }
}
