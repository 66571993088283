import React, { Component } from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


export default class LoadingInfo extends Component {
    constructor(props){
        super(props)
        this.state={
            open:false,
            message:'',
            title:''
        }
    }
    componentDidMount = () =>{
        console.log(this.props)
        this.setState({
            open:this.props.open,
            message:this.props.messsage,
            title:this.props.title
        })
    }
  render() {
    return (
      <div>
          <Dialog open={this.props.open} onClose={this.props.close} maxWidth='md' fullWidth={true} >
                <DialogTitle>{this.state.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {this.props.message}
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <button type="submit" onClick={()=>{this.props.close()}} className="buttonMain">
                        <span>Close</span>
                    </button>
                </DialogActions>
            </Dialog>
      </div>
    )
  }
}
