import React, { useEffect, useState} from 'react';
import { Route, Redirect } from 'react-router-dom'


import { withRouter } from 'react-router';

const PrivateRoute = ({ component: Component, ...rest }) => {

    
  //const auth = useSelector(state => state.auth)

  const [isAuthenticated, setIsAuthenticated] = useState(null)  
  useEffect(() => {
    let token = localStorage.getItem('token')
        if(token){
            setIsAuthenticated(true)
            /* console.log(jwtDecode(token))
            let tokenExpiration = jwtDecode(token).exp;
            let dateToken = new Date(tokenExpiration)

            let dateNow = new Date();
            console.log(dateNow)
            if(tokenExpiration < dateNow.getTime()/5000){
                console.log('token  not expired' +token)
            }else{
                setIsAuthenticated(false)
                console.log('token expired' + token)
                localStorage.removeItem('token')
            } */
        } else {
          console.log('token not exist'+token)
           setIsAuthenticated(false)
        }

  })

  if(isAuthenticated === null){
    return <></>
  }

  return (
    <Route {...rest} render={props =>
      !isAuthenticated ? (
        <Redirect to='/Login'/>
      ) : (
        <Component {...props} />
      )
    }
    />
  );
};


export default withRouter(PrivateRoute);