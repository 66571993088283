import {useContext, useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import UserContext from '../helper/UserContext'
import Alert from '@mui/material/Alert';
import axios from 'axios'

export default function Login() {
    const {updateUser} = useContext(UserContext)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [err, setErr] = useState('')
    let history = useHistory()

    const loginUser = async () =>{
        try{
            let url = `${process.env.REACT_APP_BASE_API_URL}/login`
            let params = {
                email,
                password
            }
            const user = await axios.post(url,params)
            console.log(user)
            if(user.status == 200){
                if(user.data.user_type == 'Client'){
                    return null
                }else {
                    let _user = {
                        id:user.data.userID,
                        user_type:user.data.user_type,
                        name:user.data.name
                    }
                    localStorage.setItem('token', user.data.token)
                    localStorage.setItem('user_type', user.data.user_type)
                    updateUser(_user)
                    if(_user.user_type == 'Admin'){
                        history.push('/Clients')
                    } else if(!user.data.profile_completed){
                        history.push('/EditNannyProfile')
                    }
                    else{
                        history.push('/Bookings')
                    }
                }
            } else if(user.status == 400) {
                console.log(user)
                console.log('user not logged in')
            }
        }catch(err){
            setErr(err.response.data.message)
            console.log('something went wrong' + err)
        }
    }
    useEffect(()=>{
        if(localStorage.getItem('token')!=null){
            if(localStorage.getItem('user_type') == 'Admin'){
                history.push('/Clients')
            } else {
                history.push('/Bookings')
            }
        } else {
            updateUser(null)
        }
    },[])
    return (
        <div className="login">
            <h1>The Rocket House</h1>
            <div className="loginContainer">
                <input type="text" name="email" id='email' placeholder="email" onChange={(e)=>{setEmail(e.target.value)}} className="loginInput"/>
                <input type="password" name="password" id='password' placeholder="password" onChange={(e)=>{setPassword(e.target.value)}} className="loginInput"/>
                <button type="submit" name="login" id="login" className="loginButton" onClick={()=>{loginUser()}}>Login</button>
                <a href="/forgetPassword" className='forgetPasswordLink'>Forget password?</a>
            </div>
            {err != '' ? 
            <Alert severity="error">{err}</Alert> : null }
        </div>
    )
}
