import React, { Component } from 'react'
import UserContext from '../helper/UserContext'
import Header from './common/Header'
import { TextField, Button, Checkbox, FormControlLabel, Table, TableBody, TableCell,TableContainer,TableHead,TableRow, Backdrop, CircularProgress } from '@mui/material'
import { Label } from '@mui/icons-material'
import axios from 'axios'
import Message from './common/Message'
import moment from 'moment'
import NewNotification from './notifications/NewNotification'
import TestUsers from './notifications/TestUsers'

class Notifications extends Component {
    static contextType = UserContext
    constructor(props){
        super(props)
        this.state={
            notifications:[],
            activeTab:'newnotification',
            totalUserCount:0,
            teacherCount:0,
            familyCount:0,
            title:'',
            message:'',
            testUsers:[],
            users:[],
            teachersSelected:false,
            clientsSelected:false,
            isTesting:false
        }
    }
    componentDidMount(){
        this.getAllNotifications()
    }
    getAllNotifications = async () =>{
        try{
            let url =`${process.env.REACT_APP_BASE_API_URL}/management/notifications/getAllNotifications`
            let config={
                headers:{
                    authorization:'Bearer ' + localStorage.getItem('token')
                }
            }
            let allNotifications = await axios.get(url,config)
            if(allNotifications.status == 200){
                this.setState({
                    notifications:allNotifications.data.notifications
                })
            }
            console.log(allNotifications)
        }catch(err){
            console.log(err)
        }
    }
  render() {
    return (
        this.context.isLoading ?
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={this.context.isLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop> :
        <div className="contentContainer">
            <Header header={'Notifications'} user={this.context.user} hideAction={true}/>
            <div className='notificationContainer'>
                <div className='tabs'>
                    <div className={`tab ${this.state.activeTab == 'newnotification' ? '-activeTab' : ''}`} onClick={()=>{this.setState({activeTab:'newnotification'})}}>New Notification</div>
                    <div className={`tab ${this.state.activeTab == 'notifications' ? '-activeTab' : ''}`} onClick={()=>{this.setState({activeTab:'notifications'})}}>Notifications</div>
                    {/* <div className={`tab ${this.state.activeTab == 'testusers' ? '-activeTab' : ''}`} onClick={()=>{this.setState({activeTab:'testusers'})}}>Test Users</div> */}
                </div>
                <div className={`newNotification ${this.state.activeTab == 'newnotification' ? "showTab" : ""}`} id="newNotification">
                    <NewNotification/>
                </div>
                <div className={`notifications ${this.state.activeTab == 'notifications' ? "showTab" : ""}`} id='notifications'>
                    <TableContainer className='notificationTableContainer'>
                        <Table>
                            <TableHead className='header'>
                                <TableRow>
                                    <TableCell className='cell'>Notification</TableCell>
                                    <TableCell className='cell'>Date</TableCell>
                                    <TableCell className='cell'>Read/Total</TableCell>
                                    <TableCell className='cell'>Status</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className='content'>
                                {this.state.notifications.map(n=>
                                    <TableRow>
                                        <TableCell className='cell' style={{maxWidth:500}}><span style={{fontWeight:500}}>{n.title}</span><br/>{n.message}</TableCell>
                                        <TableCell className='cell'>{moment(n.created_at).format("DD / MM / YYYY")}</TableCell>
                                        <TableCell className='cell'>{n.readCount}/{n.totalUser}</TableCell>
                                        <TableCell className='cell'>{n.status == 'Sending' ? 'Sent' : n.status}</TableCell>
                                    </TableRow> 
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                {/* <div className={`newNotification ${this.state.activeTab == 'testusers' ? "showTab" : ""}`} id="newNotification">
                    <TestUsers/>
                </div> */}
            </div>
            <Message message={this.state.resultMessage} open={this.state.showMessage} close={this.closeMessage}/>
        </div>
    )
  }
}

export default Notifications