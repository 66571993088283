import React, { useState } from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { TextField } from '@mui/material';


export default function Message(props) {
    const {open, close, message} = props
    

    return (
        <div>
            <Dialog open={open} onClose={close}>
                <DialogTitle>Info</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                    {message}
                    </DialogContentText>
                </DialogContent>
                
                <DialogActions>
                    <button type="submit" onClick={close} className="buttonMain">
                        <span>Close</span>
                    </button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
