import React, { Component } from 'react'
import axios from 'axios'
import { Link, withRouter } from 'react-router-dom'
import Header from './common/Header'
import UserContext from '../helper/UserContext'
import moment from 'moment'
import { Backdrop, CircularProgress } from '@mui/material/';

class BookingDetails extends Component {
    static contextType = UserContext
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            clientInfo: [],
            client: [],
            address: [],
            nanny: [],
            bookingDetails: []
        }
    }
    async componentDidMount() {
        console.log('booking details')
        //await this.getClientDetails()
        await this.getBookingDetails()
    }
    async getClientDetails() {
        this.setState({ isLoading: true })
        let client_id = this.props.match.params._id
        try {
            let url = `${process.env.REACT_APP_BASE_API_URL}/management/clients/clientDetail?client_id=${client_id}`
            let config = {
                headers: {
                    authorization: 'Bearer ' + localStorage.getItem('token')
                }
            }
            const client = await axios.get(url, config)
            console.log(client)
            if (client.status == 200) {
                this.setState({
                    clientInfo: client.data.clientInfo,
                    bookings: client.data.clientInfo.bookings,
                    address: client.data.clientInfo.address,
                    isLoading: false
                })
            } else {
                console.log('something went wrong!')
            }
        } catch (err) {
            console.log(err)
        }
    }
    getBookingDetails = async () => {
        this.setState({ isLoading: true })
        let booking_id = this.props.match.params._id
        let url = ''
        try {
            if (localStorage.getItem('user_type') == 'Nanny') {
                console.log('nanny')
                url = `${process.env.REACT_APP_BASE_API_URL}/nannies/bookingDetails?booking_id=${booking_id}`
            } else {
                url = `${process.env.REACT_APP_BASE_API_URL}/management/bookings/bookingDetails?booking_id=${booking_id}`
            }
            let config = {
                headers: {
                    authorization: 'Bearer ' + localStorage.getItem('token')
                }
            }
            const booking = await axios.get(url, config)
            console.log(booking)
            if (booking.status == 200) {
                this.setState({
                    client: booking.data.bookingDetails.client,
                    address: booking.data.bookingDetails.address,
                    nanny: booking.data.bookingDetails.nanny,
                    bookingDetails: booking.data.bookingDetails.bookingDetails,
                    isLoading: false
                })
            }
        } catch (err) {
            console.log(err)
        }
    }
    goBack = () => {
        this.props.history.goBack()
    }
    cancelBooking = async () => {
        try {
            let url = `${process.env.REACT_APP_BASE_API_URL}/nannies/updateBooking`
            let params = {
                booking_id: this.state.bookingDetails.booking_id,
                status: 'Cancelled'
            }
            let config = {
                headers: {
                    authorization: 'Bearer ' + localStorage.getItem('token')
                }
            }
            const approveBooking = await axios.post(url, params, config)
            console.log(approveBooking)
        } catch (err) {
            console.log(err)
        }
    }

    approveBooking = async () => {
        try {
            let url = `${process.env.REACT_APP_BASE_API_URL}/nannies/updateBooking`
            let params = {
                booking_id: this.state.bookingDetails.booking_id,
                status: 'Approved'
            }
            let config = {
                headers: {
                    authorization: 'Bearer ' + localStorage.getItem('token')
                }
            }
            const approveBooking = await axios.post(url, params, config)
            console.log(approveBooking)
        } catch (err) {
            console.log(err)
        }
    }
    render() {
        return (
            this.state.isLoading ?
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={this.state.isLoading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                :
                <div className="contentContainer">
                    <Header header={'Booking Details'} user={this.context.user} action={this.openReferenceModal} showBack={true} buttonTitle={'Generate Reference Code'} />
                    <div className='clientDetailContainer'>
                        <div className='infoContainer'>
                            <span className='title'>Client Information</span>
                            <div style={{display:'flex', flexDirection:'row'}}>
                                <img src={this.state.client.photo_url != null ? this.state.client.photo_url : require('../assets/profile.png')} style={{width:90, height:90, borderRadius:45}}/>
                                <div style={{display:'flex', flexDirection:'column'}}>
                                    <Link
                                        to={{
                                            pathname: `/clientDetails/${this.state.client.client_id}`,
                                        }}
                                    >
                                        <span className='name'>{this.state.client.name}</span>
                                    </Link>
                                    <span>{this.state.client.email}</span>
                                    <span>{this.state.client.phone}</span>
                                </div>
                            </div>
                        </div>
                        <div className='infoContainer'>
                            <span className='title'>Nanny Information</span>
                            <div style={{display:'flex', flexDirection:'row'}}>
                                <img src={this.state.nanny.photo_url != null ? this.state.nanny.photo_url : require('../assets/profile.png')} style={{width:90, height:90, borderRadius:45}}/>
                                <div style={{display:'flex', flexDirection:'column'}}>
                                    <Link to={{
                                        pathname: `/nannyDetails/${this.state.nanny.nanny_id}`,
                                    }}><span className='name'>{this.state.nanny.name}</span></Link>
                                    <span>{this.state.nanny.phone}</span>
                                </div>
                            </div>
                        </div>
                        <div className='infoContainer bioFix'>
                            <span className='title'>Booking Information</span>
                            <span>Service Type: {this.state.bookingDetails.service_type}{this.state.bookingDetails.isRepating ? 'Repeat booking' : ''}</span>
                            <span>Date: {moment(this.state.bookingDetails.startTime).format('ll')}</span>
                            <span>Time: {moment(this.state.bookingDetails.startTime).format('HH:mm')} - {moment(this.state.bookingDetails.endTime).format('HH:mm')} {this.state.bookingDetails.duration} hours</span>
                            <span>Booking note: {this.state.bookingDetails.notes}</span>
                            <span>{this.state.bookingDetails.status}{this.state.bookingDetails.status == 'Cancelled' ? ` by ${this.state.bookingDetails.updated_by}` : ``}</span>
                            <span>{this.state.bookingDetails.status == 'Cancelled' ? `Cancellation Note: ${this.state.bookingDetails.cancellationNote}` : ``}</span>
                        </div>
                        <div className='infoContainer bookings'>
                            <span className='title'>Booking Address</span>
                            {localStorage.getItem('user_type') != 'Nanny' ? <span className='name'>{this.state.address.address_name}</span> : null}
                            <span>{this.state.address.address_line_1}</span>
                            <span>{this.state.address.address_line_2}</span>
                            <span>{this.state.address.post_code}</span>
                        </div>
                        {this.context.user.user_type == 'Nanny' ?
                            <div style={{ display: 'flex' }}>
                                {this.state.bookingDetails.status != 'Approved' && this.state.bookingDetails.status == 'Awaiting confirmation' ? <button type="submit" name="login" id="login" className="saveButton" onClick={() => { this.approveBooking() }}>Approve</button> : null}
                                <button type="submit" name="login" id="login" className="saveButton cancel" onClick={() => { this.cancelBooking(this.state.bookingDetails.booking_id) }} style={{ display: this.state.bookingDetails.status == 'Cancelled' ? 'none' : 'block' }}>Cancel</button>  </div> : null}
                    </div>
                </div>
        )
    }
}
export default withRouter(BookingDetails)