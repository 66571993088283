import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";


export default function RefereneceCodeList(props) {
    const {open, close, codeList} = props

  return (
    <div>
      <Dialog open={open} onClose={close} maxWidth="md" fullWidth={true}>
        <DialogTitle>Reference Codes</DialogTitle>

          <DialogContent >
            <div className="referenceCodeListHeader">
                <span style={{width:400, marginRight:10}}>Email</span>
                <span style={{width:150, marginRight:10}}>Reference Code</span>
                <span style={{width:100, marginRight:10}}>Is Used?</span>
                <span style={{width:200}}>Membership Type</span>
            </div>
            {codeList.map(c=>
                <div className="referenceCodeList">
                    <span style={{width:400, marginRight:10}}>{c.user_email}</span>
                    <span style={{width:150, marginRight:10}}>{c.referenceCode}</span>
                    <span style={{width:100, marginRight:10}}>{c.isUsed ? `true` : `false`}</span>
                    <span style={{width:200}}>{c.membershipType}</span>
                </div>    
            )}
          </DialogContent>
        </Dialog>
        
    </div>
  );
}
