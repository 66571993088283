import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import Header from './common/Header'
import UserContext from '../helper/UserContext'
import AddAPhotoOutlinedIcon from '@mui/icons-material/AddAPhotoOutlined';
import axios from 'axios';
import LoadingInfo from './common/LoadingInfo';
import {Checkbox} from '@mui/material'

class EditNannyProfile extends Component {
    static contextType = UserContext
    constructor(props){
        super(props)
        this.state={
          selectedFile:'',
          name:'',
          email:'',
          phone:'',
          bio:'',
          languages:'',
          experience:'',
          isModalOpen:false,
          services:[],
          skills:[],
          fileUpdated:false
        }
    }
    async componentDidMount(){
      await this.getAllServices()
      await this.getAllSkills()
      await this.fetchNanny()
    }
    async fetchNanny(){
      let url = `${process.env.REACT_APP_BASE_API_URL}/nannies/me`
      let config = {
        headers:{
          authorization:'Bearer ' + localStorage.getItem('token')
        }
      }
      const me = await axios.get(url,config)
      console.log(me)
      if(me.status == 200 && me.data){
        this.setState({
          name:me.data.me.name,
          email:me.data.me.email,
          phone:me.data.me.phone,
          bio:me.data.me.bio,
          languages:me.data.me.languages,
          experience:me.data.me.experience,
          fileURL:me.data.me.photo_url
        })
        me.data.me.services.map(s=>{
          let _tmpServices = this.state.services
          let index = _tmpServices.findIndex(_s=>_s.service_name == s.service_name)
          _tmpServices[index] = {..._tmpServices[index], selected:true}
          this.setState({
            services:_tmpServices
          })
        })

        me.data.me.skills.map(s=>{
          let _tmpSkills = this.state.skills
          let index = _tmpSkills.findIndex(_s=>_s.skill_name == s)
          _tmpSkills[index] = {..._tmpSkills[index], selected:true}
          this.setState({
            skills:_tmpSkills
          })
        })
      } else {

        this.setState({
          email:this.context.user.email,
          name:this.context.user.name
        })
      }
    }

    getAllSkills = async () =>{
      let _allSkills = []
      try{
        let url = `${process.env.REACT_APP_BASE_API_URL}/nannies/skills/getAllSkills`
        let config = {
          headers:{
            authorization:'Bearer ' + localStorage.getItem('token')
          }
        }
        const skills = await axios.get(url,config)
        console.log(skills)
        if(skills.status == 200){
          skills.data.AllSkills.map(s=>{
            let _tmpSkills = {
              skill_name:s.skill_name,
              skill_id:s.skill_id,
              selected:false
            }
            _allSkills.push(_tmpSkills)
          })
          this.setState({
            skills:_allSkills
          })
        }
      }catch(err){

      }
    }
    getAllServices = async () =>{
      let _allServices = []
      try{
        let url = `${process.env.REACT_APP_BASE_API_URL}/nannies/services/getAllServices`
        let config = {
          headers:{
            authorization:'Bearer ' + localStorage.getItem('token')
          }
        }
        const services = await axios.get(url,config)
        console.log(services)
        if(services.status == 200){
          services.data.AllServices.map(s=>{
            let _tmpServices = {
              service_id:s.service_id,
              service_image_url:s.service_image_url,
              service_name:s.service_name,
              selected:false
            }
            _allServices.push(_tmpServices)
          })
          this.setState({
            services:_allServices
          })
        }
      }catch(err){
        console.log(err)
      }
    }
    selectFile = (event) =>{
      this.fileInput.click()
    }
    handleFile = (e)=>{
      console.log(e.target.files[0])
      this.setState({
        selectedFile:e.target.files[0],
        fileURL:URL.createObjectURL(e.target.files[0]),
        fileUpdated:true
      })
    }
    handleText = (e)=>{
      this.setState({
        [e.target.name]:e.target.value
      })
    }
    saveNanny = async () =>{
      
      this.setState({isModalOpen:true, message:'Please wait...'})
      try{
        let url = `${process.env.REACT_APP_BASE_API_URL}/nannies/profile/updateMyProfile`
        let config = {
          headers:{
            authorization:'Bearer ' + localStorage.getItem('token')
          }
        }
        let _tmpServices = ''
        this.state.services.map(s=>{
          if(s.selected){
            _tmpServices = _tmpServices + s.service_id + ','
          }
        })
        let _tmpSkills = ''
        this.state.skills.map(s=>{
          if(s.selected){
            _tmpSkills = _tmpSkills + s.skill_name + '-'
          }
        })
        console.log(_tmpServices)
        const formData = new FormData();
        formData.append("name", this.state.name);
        formData.append("email", this.state.email);
        formData.append("phone", this.state.phone);
        formData.append("experience", this.state.experience);
        formData.append("languages", this.state.languages);
        formData.append("bio", this.state.bio);
        formData.append("services", _tmpServices)
        formData.append("skills", _tmpSkills)
        this.state.fileUpdated ? formData.append("user_image", this.state.selectedFile) : formData.append('fileURL', this.state.photo_url)
        formData.append('photo_updated', this.state.fileUpdated)
        const saveNanny = await axios.post(url,formData,config)
        console.log(saveNanny)
        this.setState({message:'The nanny has been added successfully. '})
      }catch(err){
        console.log(err)
      }
    }
    closeModal = () =>{
      this.setState({
        selectedFile:'',
        name:'',
        email:'',
        phone:'',
        bio:'',
        languages:'',
        experience:'',
        isModalOpen:false
      })
    }
    handleServices = (service_id) =>{
      let _allServices = this.state.services
      let index = _allServices.findIndex(s=>s.service_id == service_id)
      _allServices[index] = {..._allServices[index], selected:!this.state.services[index].selected}
      this.setState({services:_allServices})
    }

    handleSkills = (skill_id) =>{
      let _allSkills = this.state.skills
      let index = _allSkills.findIndex(s=>s.skill_id == skill_id)
      _allSkills[index] = {..._allSkills[index], selected:!this.state.skills[index].selected}
      this.setState({skills:_allSkills})
    }
  render() {
    return (
        <div className='contentContainer'>
            <Header header={"Edit My Profile"} user={this.context.user} showBack={false} buttonTitle={"Add New Nanny"}/>
            <div className='clientDetailContainer'>
              <div className='infoContainer'>
                <span className='title'>Photo</span>
                <span className='imagePicker' onClick={()=>{this.selectFile()}}>
                  {this.state.selectedFile && this.state.fileURL == '' ?
                    <AddAPhotoOutlinedIcon className='photoIcon'/>
                    :
                    <img src={this.state.fileURL}/>
                  }
                </span>
                <input type='file' ref={(ref)=>this.fileInput = ref} style={{display:'none'}} onChange={(e)=>{this.handleFile(e)}}/>
              </div>
              <div className='infoContainer heightFix'>
                <span className='title'>Contact Information</span>
                <div className='newNanny'>
                  <input type="text" name="name" id='name' placeholder="name" onChange={(e)=>{this.handleText(e)}} value={this.state.name}/>
                  <input type="text" name="email" id='email' placeholder="email" onChange={(e)=>{this.handleText(e)}} value={this.state.email}/>
                </div>
                <div className='newNanny'>
                  <input type="text" name="phone" id='phone' placeholder="phone" onChange={(e)=>{this.handleText(e)}} value={this.state.phone}/>
                </div>
              </div>
              <div className='infoContainer heightFix' style={{paddingBottom:15}}>
                <span className='title'>Professional Information</span>
                <div className='newNanny'>
                  <textarea name="bio" id='bio' placeholder="bio" onChange={(e)=>{this.handleText(e)}} multiple={true} style={{height:125, width:'100%'}} value={this.state.bio} />
                </div>
                <div className='newNanny'>
                  <input type="text" name="experience" id='experience' placeholder="experience (5 years)" onChange={(e)=>{this.handleText(e)}} value={this.state.experience}/>
                  <input type="text" name="languages" id='languages' placeholder="languages (seperate with comma)" onChange={(e)=>{this.handleText(e)}} value={this.state.languages}/>
                </div>
              </div>
              <div className='infoContainer heightFix' style={{paddingBottom:15}}>
                <span className='title'>Services</span>
                <div className='newNanny' style={{flexWrap:'wrap'}}>
                  {this.state.services.map(s=>
                    <div key={s.service_id} onClick={()=>{this.handleServices(s.service_id)}} className="checkbox">
                      <Checkbox checked={s.selected}/>
                      {s.service_name}
                    </div>  
                  )}
                </div>
              </div>
              <div className='infoContainer heightFix' style={{paddingBottom:15}}>
                <span className='title'>Skills</span>
                <div className='newNanny' style={{flexWrap:'wrap'}}>
                  {this.state.skills.map(s=>
                    <div key={s.skill_id} onClick={()=>{this.handleSkills(s.skill_id)}} className='checkbox' style={{width:400}}>
                      <Checkbox checked={s.selected}/>
                      {s.skill_name}
                    </div>  
                  )}
                </div>
              </div>
                <button type="submit" name="login" id="login" className="saveButton" onClick={()=>{this.saveNanny()}}>Save</button>
            </div>
            <LoadingInfo title={"Add new nanny"} message={this.state.message} open={this.state.isModalOpen} close={this.closeModal}/>
        </div>
    )
  }
}
export default withRouter(EditNannyProfile)