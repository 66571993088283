import React, { useState } from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { TextField } from '@mui/material';


export default function CreateNanny(props) {
    const {open, createNanny, close, isSuccess, hasError, message} = props
    const [email, setEmail] = useState()
    const [name, setName] = useState()

    const closePopup = () =>{
        setEmail('')
        setName('')
        close()
    }
    
    return (
        <div>
            <Dialog open={open} onClose={close}>
                <DialogTitle>Invite New Teacher</DialogTitle>
                {isSuccess ? 
                <DialogContent>
                    <DialogContentText>
                    The code will be sent to the teacher's email.
                    </DialogContentText>
                </DialogContent>
                :
                <DialogContent>
                    <DialogContentText>
                        The code will be sent to the teacher's email.
                    </DialogContentText>
                    <TextField id="name" label="Teacher Name" variant="standard" className='generate' onChange={(e)=>{setName(e.target.value)}}/>
                    <TextField id="email" label="Teacher Email" variant="standard" className='generate'onChange={(e)=>{setEmail(e.target.value)}} />
                    
                </DialogContent> }
                {isSuccess ?
                <DialogActions>
                    <button type="submit" onClick={closePopup} className="buttonMain">
                        <span>Close</span>
                    </button>
                </DialogActions> : 
                <div>
                    {hasError ? <span className='errorMessage'>{message}</span> : null}
                    <DialogActions>
                        <button type="submit" onClick={()=>{createNanny(name, email)}} className="buttonMain">
                            <span>Generate</span>
                        </button>
                    </DialogActions>
                </div>
                 }
            </Dialog>
        </div>
    )
}
