import React, { Component } from 'react'
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import ProtectedRoute from './helper/auth'

import Login from './components/Login'
import Registration from './components/Registration'
import ClientDetails from './components/ClientDetails'
import Bookings from './components/Bookings'
import BookingDetails from './components/BookingDetails'
import Nannies from './components/Nannies'
import NannyDetails from './components/NannyDetails'
import Reviews from './components/Reviews'
import EditNannyProfile from './components/EditNannyProfile'
import Clients from './components/Clients'
import Availability from './components/Availability'
import Settings from './components/Settings'
import ForgetPassword from './components/ForgetPassword'
import Services from './components/Services'
import AddService from './components/AddNewService'
import Skills from './components/Skills'
import Notifications from './components/Notifications'
import Jobs from './components/Jobs'
import Activities from './components/Activities'


class Routes extends Component {
    render() {
        return (
            <Switch>
                <Route exact path="/" component={Login}/>
                <Route exact path="/Login" component={Login}/>
                <Route exact path="/Register" component={Registration}/>
                <Route exact path="/ForgetPassword" component={ForgetPassword}/>
                <ProtectedRoute exact path="/Clients" component={Clients}/>
                <ProtectedRoute exact path='/clientDetails/:_id' component={ClientDetails}/>
                <ProtectedRoute exact path='/Bookings' component={Bookings}/>
                <ProtectedRoute exact path='/bookingDetails/:_id' component={BookingDetails}/>
                <ProtectedRoute exact path='/Teachers' component={Nannies}/>
                <ProtectedRoute exact path='/teacherDetails/:_id' component={NannyDetails} />
                <ProtectedRoute exact path='/Reviews' component={Reviews}/>
                <ProtectedRoute exact path='/EditTeacherProfile' component={EditNannyProfile}/>
                <ProtectedRoute exact path='/Availability' component={Availability}/>
                <ProtectedRoute exact path='/Services' component={Services}/>
                <ProtectedRoute exact path='/AddNewService' component={AddService}/>
                <ProtectedRoute exact path='/UpdateService/:_id' component={AddService}/>
                <ProtectedRoute exact path='/Skills' component={Skills}/>
                <ProtectedRoute exact path='/Settings' component={Settings}/>
                <ProtectedRoute exact path='/Notifications' component={Notifications}/>
                <ProtectedRoute exact path='/Jobs' component={Jobs}/>
                <ProtectedRoute exact path='/Activities' component={Activities}/>

            </Switch>
        )
    }
}
export default Routes