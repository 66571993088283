import React, { Component } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { TextField } from "@mui/material";

export default class Cancellation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      message: "",
    };
  }
  componentDidMount = () => {
    this.setState({
      open: this.props.open,
    });
  };

  delete = () =>{
    console.log(this.state.message)
    this.props.deleteJob(this.state.message);
  }

  render() {
    return (
      <div>
        <Dialog open={this.props.open} onClose={this.props.close}>
          <DialogTitle>Job Delete</DialogTitle>
          <DialogContent style={{height:'auto'}}>
            <DialogContentText>
              Please type the reason for the cancellation.
            </DialogContentText>
            <TextField
              id="reason"
              label="Reason"
              variant="standard"
              className="generate"
              value={this.state.message}
              onChange={(e) => {
                this.setState({ message: e.target.value });
              }}
              inputProps={{ maxLength: 175, }}
              multiline
              rows={4}
              style={{height:125}}
            />
            <DialogContentText>
              <span style={{fontSize:11}}>Max. Character: {this.state.message.length} / 175</span>
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <button
              type="submit"

              onClick={this.delete}
              className="buttonMain"
            >
              <span>Delete</span>
            </button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
