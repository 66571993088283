import React, { Component } from "react";
import UserContext from "../helper/UserContext";
import Header from "./common/Header";
import {
  Backdrop,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
} from "@mui/material/";
import { DeleteOutline } from "@mui/icons-material";
import GenerateReference from "./common/GenerateReference";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from "moment";
import { Star, Close, Check } from "@mui/icons-material";

function render_star(c){
    let _star = <img src={require('../assets/full_star.png')} style={{width:20, height:20, marginLeft:5}} />
    let _emptyStar = <img src={require('../assets/empty_star.png')} style={{width:20, height:20, marginLeft:5}} />
  
    let stars = []
    for(let i=0; i<5; i++){
      if(i<c) {stars.push(_star)}else{stars.push(_emptyStar)}
  
  
    }
    return(
      <div style={{flexDirection:'row'}}>
        {stars.map(s=>
          s
        )}
      </div>
    )
  }

export default class Reviews extends Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      reviews: [],
      isLoading: false,
      referenceModal: false,
      isSuccess: false,
      isFetching: false,
    };
  }

  async componentDidMount() {
    this.getAllReviews();
    this.setState({ isLoading: this.context.isLoading });
  }

  getAllReviews = async () => {
    this.setState({ isFetching: true });
    try {
      let url = `${process.env.REACT_APP_BASE_API_URL}/management/reviews/getAllReviews`;
      let config = {
        headers: {
          authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      const reviews = await axios.get(url, config);
      this.setState({
        isFetching: false,
        reviews: reviews.data.reviews
      });
    } catch (err) {
      console.log("err");
    }
  };

  openReferenceModal = () => {
    this.setState({
      referenceModal: !this.state.referenceModal,
    });
  };
  generate = async (client_email) => {
    let url = `${process.env.REACT_APP_BASE_API_URL}/management/clients/generateReferenceCode`;
    let params = {
      client_email,
    };
    let config = {
      headers: {
        authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    const generateCode = await axios.post(url, params, config);
    if (generateCode.status == 200) {
      this.setState({ isSuccess: true });
    }
  };

  changeStatus = async (_id) => {
    try {
      let url = `${process.env.REACT_APP_BASE_API_URL}/management/reviews/deleteReview`;
      let config = {
        headers: {
            authorization:'Bearer ' + localStorage.getItem('token')
        },
      };
      let params = {
        review_id: _id,
      };
      console.log(params)
      const status = await axios.post(url, params, config);
      if (status.status == 200) {
        await this.getAllReviews();
      }
    } catch (err) {
      console.log(err);
    }
  };
  render() {
    return this.context.isLoading ? (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={this.context.isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    ) : this.state.isFetching ? (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={this.state.isFetching}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    ) : (
      <div className="contentContainer">
        <Header
          header={"Reviews"}
          user={this.context.user}
          hideAction={true}
        />
        <div className="clientDetailContainer">
          <TableContainer className="notificationTableContainer">
            <Table>
              <TableHead className="header">
                <TableRow>
                  <TableCell className="cell">Client</TableCell>
                  <TableCell className="cell">Nanny/Teacher</TableCell>
                  <TableCell className="cell">Review</TableCell>
                  <TableCell className="cell">Star</TableCell>
                  <TableCell className="cell">Date</TableCell>
                  <TableCell className="cell"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="content">
                {this.state.reviews.map((r) => (
                  <TableRow key={r.review_id}>
                    <TableCell className="cell" style={{verticalAlign:'top'}}>{r.client}</TableCell>
                    <TableCell className="cell" style={{verticalAlign:'top'}}>{r.nanny}</TableCell>
                    <TableCell className="cell" style={{maxWidth:400,minWidth:300}}>{r.review}</TableCell>
                    <TableCell className="cell" style={{minWidth:140}}>{render_star(r.star)}</TableCell>
                    <TableCell className="cell" style={{minWidth:100}}>{moment(r.reviewed_at).format('DD-MM-YYYY')}</TableCell>
                    <TableCell className="cell"><span className="removeReviewButton" onClick={()=>{this.changeStatus(r._id)}}><DeleteOutline /></span></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <GenerateReference
          open={this.state.referenceModal}
          generate={this.generate}
          close={this.openReferenceModal}
          isSuccess={this.state.isSuccess}
        />
      </div>
    );
  }
}
