import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import UserContext from '../../helper/UserContext'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

class Header extends Component {
    static contextType = UserContext
    constructor(props){
        super(props)
        this.state={
            initials:''
        }
    }

    getInitials = (_user) =>{
        let _name = _user.name.split(' ')
        let _x = ''
        _name.forEach(_n=>{
            //console.log(_n)
            let _i = _n.slice(0,1) 
            _x = _x + _i
        })
        this.setState({initials:_x})
    }

    componentDidMount(){
        this.getInitials(this.props.user)
        console.log(this.props)
    }
    goBack=()=>{
        this.props.history.goBack()
    }
    render() {
        return (
            <div className="header">
                <div className="leftContainer">
                    {this.props.showBack ?
                        //<a href="#" onClick={()=>{this.goBack()}}>Go Back</a> 
                        <button type='submit' name='back' id='back' className='backButton' onClick={()=>{this.goBack()}}>
                            <ArrowBackIosNewIcon/>
                        </button>
                    : null }
                    <h3>{this.props.header}</h3>
                </div>
            <div className="rightContainer">
                {this.props.user.user_type =='Admin'?
                <div style={{display:'flex', flexDirection:'row'}}>
                    {this.props.showClientList ? 
                        <button type="submit" className="buttonMain unregisteredButton" style={{marginRight:25}} onClick={()=>{this.props.showList()}}>
                            <span>Unregistered Clients</span>
                        </button> : null
                    }
                    {this.props.hideAction ? null :
                    <button type="submit" className="buttonMain" onClick={()=>{this.props.action()}}>
                        <span>{this.props.buttonTitle}</span>
                    </button> }
                </div>
                :null}
                <span className="username">{this.state.initials}</span>
            </div>
        </div>
        )
    }
}

export default withRouter(Header)