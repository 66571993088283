import React, { useState } from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { TextField } from '@mui/material';


export default function CreateAdmin(props) {
    const {open, createAdmin, close, isSuccess, message,hasError} = props
    const [email, setEmail] = useState()
    const [name, setName] = useState()

    return (
        <div>
            <Dialog open={open} onClose={close}>
                <DialogTitle>Add New Admin</DialogTitle>
                {isSuccess ? 
                <DialogContent>
                    <DialogContentText>
                    The code will be sent to the user's email.
                    </DialogContentText>
                </DialogContent>
                :
                <DialogContent>
                    <DialogContentText>
                        The code will be sent to the user's email.
                    </DialogContentText>
                    <TextField id="name" label="User Name" variant="standard" className='generate' onChange={(e)=>{setName(e.target.value)}}/>
                    <TextField id="email" label="User Email" variant="standard" className='generate'onChange={(e)=>{setEmail(e.target.value)}} />
                </DialogContent> }
                {isSuccess ?
                <DialogActions>
                    <button type="submit" onClick={close} className="buttonMain">
                        <span>Close</span>
                    </button>
                </DialogActions> : 
                <div>
                    {hasError ? <span className='errorMessage'>{message}</span>: null}
                <DialogActions>
                    <button type="submit" onClick={()=>{createAdmin(name, email)}} className="buttonMain">
                        <span>Add</span>
                    </button>
                </DialogActions> 
                </div>}
            </Dialog>
        </div>
    )
}
